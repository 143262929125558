import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FeatherIcon from "feather-icons-react";

import Heading from "components/heading/heading";
import { BasicFormWrapper } from "container/styled";
import { useTranslation } from "react-i18next";

const { Option } = Select;

type UserFormProps = {
  form?: any;
  user?: any;
  userAlreadyExists?: boolean;
};

const UserForm = ({ form, user, userAlreadyExists }: UserFormProps) => {
  const { t } = useTranslation();

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col xs={24}>
            <div className="create-account-form">
              <Heading as="h4">
                {t("users:users.profile", "User's Profile")}
              </Heading>
              <Form.Item
                name="email"
                label={t("user-view-details.email", "Email Address")}
                //   requiredMark
                tooltip={t(
                  "users:email.usage.purpose.tooltip",
                  "This email will be used for logging into the app"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "users.create:email-validation",
                      "Email is required"
                    ),
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                ]}
                required
              >
                <Input
                  prefix={<FeatherIcon icon="mail" size={14} />}
                  placeholder={t("user-view-details.email", "Email Address")}
                />
              </Form.Item>
              <Form.Item
                name="firstName"
                required
                label={t("global:first.name", "First Name")}
              >
                <Input
                  placeholder={t("global:first.name", "First Name") + "*"}
                  required
                  disabled={userAlreadyExists}
                  prefix={<FeatherIcon icon="user" size={14} />}
                />
              </Form.Item>

              <Form.Item
                name="lastName"
                required
                label={t("global:last.name", "Last Name")}
              >
                <Input
                  disabled={userAlreadyExists}
                  placeholder={t("global:last.name", "Last Name") + "*"}
                  prefix={<FeatherIcon icon="users" size={14} />}
                  required
                />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label={t("global.phone-number	", "Phone Number")}
              >
                <Input
                  disabled={userAlreadyExists}
                  placeholder={t("global.phone-number	", "Phone Number")}
                  prefix={<FeatherIcon icon="phone" size={14} />}
                />
              </Form.Item>

              {/* <Form.Item
                  name="languageId"
                  required
                  label={t(
                    "system-languages.default-language",
                    "Default Language"
                  )}
                  requiredMark
                  rules={[
                    {
                      required: true,
                      message: t(
                        "validations:language.required",
                        "Please select language"
                      ),
                    },
                  ]}
                >
                  <Select
                    className="sDash_fullwidth-select"
                    // aria-required
                    style={{ color: "rgb(90, 95, 125)" }}
                    disabled={userAlreadyExists}
                  >
                    {languages &&
                      languages.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item> */}

              <Form.Item
                name="comments"
                label={t("global:comments", "Comments")}
              >
                <TextArea
                  disabled={userAlreadyExists}
                  placeholder={t("global:comments", "Comments")}
                  rows={3}
                />
              </Form.Item>
              {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button title="Submit" type="primary" size="large">
                  Submit
                </Button>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
};

export default UserForm;
