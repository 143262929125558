import { useState, useEffect } from "react";
import { DestinationsApi } from "api/api";
import { useCompanyId } from "utility/useCompanyId";
import { DestinationProps } from "../destinationDataTableColumns";

export const useDestinationsFetch = ({ companyId }: DestinationProps) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
    companyId: companyId,
  });

  const fetchDestinations = async () => {
    setIsLoading(true);
    try {
      const { data } = await new DestinationsApi().apiCompaniesDestinationsGet(
        query
      );
      setData(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, [query]);

  return { data, isLoading, fetchDestinations, query, setQuery };
};
