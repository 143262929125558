import { useState, useEffect } from "react";
import { DriversApi } from "api/api";
import { DriverProps } from "../driverDataTableColumns";

const driversApi = new DriversApi();

export const useDriversFetch = ({ companyId }: DriverProps) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
    companyId: companyId,
  });

  const fetchDrivers = async () => {
    setIsLoading(true);
    try {
      const { data } = await driversApi.apiDriversCompaniesGet(query);
      setData(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, [query]);

  return { data, isLoading, fetchDrivers, query, setQuery };
};
