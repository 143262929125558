import { Button } from "components/buttons/buttons";
import { PageHeader } from "components/page-headers/page-headers";
import { CardToolbox } from "container/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { PredefinedRoles } from "constants/constants";
import { getRole } from "utility/decode-jwt";
import DealsList from "components/deals/DealsList";
import Workbook from "components/deals/Workbook";

function DealsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/deal/initiate");
  };

  const roles = getRole();
  const isFreightForwarder = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.FREIGHT_FORWARDER)
    : roles === PredefinedRoles.FREIGHT_FORWARDER;

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("deals:quotes", "Quotes")}
          buttons={[
            isFreightForwarder && (
              <Button key="add-button" onClick={handleAddClick}>
                <FeatherIcon icon="plus" size={25}></FeatherIcon>
                {t("deals.button.add", "Add new")}
              </Button>
            ),
          ]}
        />
      </CardToolbox>
      <DealsList />
    </>
  );
}
export default DealsPage;
