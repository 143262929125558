/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomOfficeDtoOrdinalPaginatedList } from '../models';
/**
 * CustomOfficesApi - axios parameter creator
 * @export
 */
export const CustomOfficesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/custom-offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomOfficesIdDelete', 'id', id)
            const localVarPath = `/api/custom-offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [name] 
         * @param {string} [address] 
         * @param {string} [zipCode] 
         * @param {string} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesIdPut: async (id: string, id2?: string, name?: string, address?: string, zipCode?: string, countryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomOfficesIdPut', 'id', id)
            const localVarPath = `/api/custom-offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (zipCode !== undefined) { 
                localVarFormParams.append('ZipCode', zipCode as any);
            }
    
            if (countryId !== undefined) { 
                localVarFormParams.append('CountryId', countryId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [address] 
         * @param {string} [zipCode] 
         * @param {string} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesPost: async (name?: string, address?: string, zipCode?: string, countryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/custom-offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (zipCode !== undefined) { 
                localVarFormParams.append('ZipCode', zipCode as any);
            }
    
            if (countryId !== undefined) { 
                localVarFormParams.append('CountryId', countryId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomOfficesApi - functional programming interface
 * @export
 */
export const CustomOfficesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomOfficesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomOfficesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomOfficeDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomOfficesGet(pageNumber, pageSize, sortBy, sortType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomOfficesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomOfficesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [name] 
         * @param {string} [address] 
         * @param {string} [zipCode] 
         * @param {string} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomOfficesIdPut(id: string, id2?: string, name?: string, address?: string, zipCode?: string, countryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomOfficesIdPut(id, id2, name, address, zipCode, countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [address] 
         * @param {string} [zipCode] 
         * @param {string} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomOfficesPost(name?: string, address?: string, zipCode?: string, countryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomOfficesPost(name, address, zipCode, countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomOfficesApi - factory interface
 * @export
 */
export const CustomOfficesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomOfficesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: any): AxiosPromise<CustomOfficeDtoOrdinalPaginatedList> {
            return localVarFp.apiCustomOfficesGet(pageNumber, pageSize, sortBy, sortType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomOfficesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [name] 
         * @param {string} [address] 
         * @param {string} [zipCode] 
         * @param {string} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesIdPut(id: string, id2?: string, name?: string, address?: string, zipCode?: string, countryId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomOfficesIdPut(id, id2, name, address, zipCode, countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [address] 
         * @param {string} [zipCode] 
         * @param {string} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomOfficesPost(name?: string, address?: string, zipCode?: string, countryId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiCustomOfficesPost(name, address, zipCode, countryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCustomOfficesGet operation in CustomOfficesApi.
 * @export
 * @interface CustomOfficesApiApiCustomOfficesGetRequest
 */
export interface CustomOfficesApiApiCustomOfficesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomOfficesApiApiCustomOfficesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CustomOfficesApiApiCustomOfficesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiCustomOfficesIdDelete operation in CustomOfficesApi.
 * @export
 * @interface CustomOfficesApiApiCustomOfficesIdDeleteRequest
 */
export interface CustomOfficesApiApiCustomOfficesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCustomOfficesIdPut operation in CustomOfficesApi.
 * @export
 * @interface CustomOfficesApiApiCustomOfficesIdPutRequest
 */
export interface CustomOfficesApiApiCustomOfficesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdPut
     */
    readonly id2?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdPut
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdPut
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdPut
     */
    readonly zipCode?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesIdPut
     */
    readonly countryId?: string
}

/**
 * Request parameters for apiCustomOfficesPost operation in CustomOfficesApi.
 * @export
 * @interface CustomOfficesApiApiCustomOfficesPostRequest
 */
export interface CustomOfficesApiApiCustomOfficesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesPost
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesPost
     */
    readonly zipCode?: string

    /**
     * 
     * @type {string}
     * @memberof CustomOfficesApiApiCustomOfficesPost
     */
    readonly countryId?: string
}

/**
 * CustomOfficesApi - object-oriented interface
 * @export
 * @class CustomOfficesApi
 * @extends {BaseAPI}
 */
export class CustomOfficesApi extends BaseAPI {
    /**
     * 
     * @param {CustomOfficesApiApiCustomOfficesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomOfficesApi
     */
    public apiCustomOfficesGet(requestParameters: CustomOfficesApiApiCustomOfficesGetRequest = {}, options?: AxiosRequestConfig) {
        return CustomOfficesApiFp(this.configuration).apiCustomOfficesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomOfficesApiApiCustomOfficesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomOfficesApi
     */
    public apiCustomOfficesIdDelete(requestParameters: CustomOfficesApiApiCustomOfficesIdDeleteRequest, options?: AxiosRequestConfig) {
        return CustomOfficesApiFp(this.configuration).apiCustomOfficesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomOfficesApiApiCustomOfficesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomOfficesApi
     */
    public apiCustomOfficesIdPut(requestParameters: CustomOfficesApiApiCustomOfficesIdPutRequest, options?: AxiosRequestConfig) {
        return CustomOfficesApiFp(this.configuration).apiCustomOfficesIdPut(requestParameters.id, requestParameters.id2, requestParameters.name, requestParameters.address, requestParameters.zipCode, requestParameters.countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomOfficesApiApiCustomOfficesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomOfficesApi
     */
    public apiCustomOfficesPost(requestParameters: CustomOfficesApiApiCustomOfficesPostRequest = {}, options?: AxiosRequestConfig) {
        return CustomOfficesApiFp(this.configuration).apiCustomOfficesPost(requestParameters.name, requestParameters.address, requestParameters.zipCode, requestParameters.countryId, options).then((request) => request(this.axios, this.basePath));
    }
}
