import { Card, Checkbox, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";
import Dimensions from "./Dimensions";

interface BasicInformationFormProps {
  hsCodes: any;
  isTruckChecked: boolean;
  handleTruckCheckboxChange: any;
}

const BasicInformation: React.FC<BasicInformationFormProps> = ({
  hsCodes,
  handleTruckCheckboxChange,
  isTruckChecked,
}) => {
  const { t } = useTranslation();
  return (
    <Card title={t("initiate-deal.basic-information", "Basic Information")}>
      {/* <Form.Item
        name="title"
        label={t("initiate-deal.title", "Title")}
        rules={[
          {
            required: true,
            message: t(
              "initiate-deal.enter-title",
              "Please enter the deal title"
            ),
          },
        ]}
      >
        <Input placeholder="Enter deal title" />
      </Form.Item> */}
      <Form.Item
        name="commodity"
        label={t("initiate-deal.commodity", "Commodity")}
        rules={[
          {
            required: true,
            message: t(
              "initiate-deal.enter-Commodity",
              "Please enter a commodity"
            ),
          },
        ]}
      >
        <TextArea placeholder="Enter commodity" rows={4} />
      </Form.Item>
      <Form.Item
        name="hsCodeId"
        label={t("initiate-deal.hs-Codes ", "HSCodes")}
        rules={[
          {
            required: true,
            message: t(
              "initiate-deal.select-HSCodes",
              "Please select a HSCode"
            ),
          },
        ]}
      >
        <Select placeholder="Select HSCode">
          {hsCodes?.items.map((code: any) => (
            <Select.Option value={code.id} key={code.id}>
              {code.code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="dedicatedTruck" valuePropName="checked">
        <Checkbox onChange={handleTruckCheckboxChange}>
          {t("initiate-deal.truck", "Dedicated Truck")}
        </Checkbox>
      </Form.Item>

      {!isTruckChecked && <Dimensions />}

      <Form.Item
        name="specialRequirements"
        label={t("initiate-deal.special-requirements", "Special Requirements")}
      >
        <TextArea placeholder="Enter any special requirements" rows={3} />
      </Form.Item>
      <Form.Item name="controlledTemperature" valuePropName="checked">
        <Checkbox>
          {t("initiate-deal.controlled-Temperature", "Controlled Temperature")}
        </Checkbox>
      </Form.Item>
      <Form.Item name="isADR" valuePropName="checked">
        <Checkbox>{t("initiate-deal.ADR", "ADR (Dangerous Goods)")}</Checkbox>
      </Form.Item>
    </Card>
  );
};

export default BasicInformation;
