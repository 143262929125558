import { Button } from "components/buttons/buttons";
import { PageHeader } from "components/page-headers/page-headers";
import { CardToolbox } from "container/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { PredefinedRoles } from "constants/constants";
import { getRole } from "utility/decode-jwt";
import Workbook from "components/deals/Workbook";

function WorkbookPage() {
  const { t } = useTranslation();

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t("deals:workbook", "Workbook")} />
      </CardToolbox>
      <Workbook />
    </>
  );
}
export default WorkbookPage;
