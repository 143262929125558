import { Card, Col, Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { CompaniesApi, CustomOfficesApi } from "api/api";
import {
  CompanyDto,
  CompanyStatus,
  CompanyTypeEnum,
  CustomOfficeDto,
} from "api/models";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface MultiStepFormProps {
  form: FormInstance<any>; // or specific form data type if available
}

const companiesApi = new CompaniesApi();
const customOfficesApi = new CustomOfficesApi();

const MultiStepForm: React.FC<MultiStepFormProps> = ({ form }) => {
  const { t } = useTranslation();
  const [shippers, setShippers] = useState<CompanyDto[]>([]);
  const [shipperTerminals, setShipperTerminals] = useState<CustomOfficeDto[]>(
    []
  );

  const [consignees, setConsignees] = useState<CompanyDto[]>([]);
  const [consigneeTerminals, setConsigneeTerminals] = useState<
    CustomOfficeDto[]
  >([]);

  useEffect(() => {
    const fetchShippers = async () => {
      try {
        const shippers = await fetchCompaniesByType(
          CompanyStatus.NUMBER_4,
          CompanyTypeEnum.NUMBER_1
        ); // shipper(client)
        setShippers(shippers.items as CompanyDto[]);
      } catch (error) {
        console.log("Error fetching Shippers:", error);
      }
    };

    const fetchConsignees = async () => {
      try {
        const clients = await fetchCompaniesByType(
          CompanyStatus.NUMBER_4,
          CompanyTypeEnum.NUMBER_1
        ); // client
        setConsignees(clients.items as CompanyDto[]);
      } catch (error) {
        console.log("Error fetching Consignees:", error);
      }
    };

    fetchShippers();
    fetchConsignees();
  }, []);

  const fetchCompaniesByType = async (
    status: CompanyStatus,
    type: CompanyTypeEnum
  ) => {
    const response = await companiesApi.apiCompaniesByTypeGet({
      status,
      type,
      pageSize: -1,
    });
    return response.data;
  };

  useEffect(() => {
    const fetchCustomOffices = async () => {
      try {
        const response = (await customOfficesApi.apiCustomOfficesGet()).data;
        setShipperTerminals(response.items as CustomOfficeDto[]);
        setConsigneeTerminals(response.items as CustomOfficeDto[]);
        console.log(response.items);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCustomOffices();
  }, []);

  const validateConsigneeTerminal = (rule: any, value: any) => {
    const shipperTerminalId = form.getFieldValue("shipperTerminalId");
    if (shipperTerminalId && value && shipperTerminalId === value) {
      return Promise.reject(
        new Error("Shipper and Consignee cannot have the same terminal.")
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Card
        title={t("initiate-quote.shipper-information", "Shipper Information")}
      >
        <Form.Item
          name="shipperId"
          label={t("initiate-quote.shipper", "Shipper")}
          rules={[
            {
              required: true,
              message: t(
                "initiate-quote.select-shipper",
                "Please select a shipper"
              ),
            },
          ]}
        >
          <Select placeholder="Select Shipper">
            {shippers.map((shipper) => (
              <Select.Option value={shipper.id} key={shipper.id}>
                {shipper.businessName} ({shipper.businessNumber})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="shipperTerminalId"
          label={t("initiate-quote.shipper-terminal", "Shipper Terminal ")}
          rules={[
            {
              required: true,
              message: t(
                "initiate-quote.select-shipper-terminal",
                "Please select a shipper terminal"
              ),
            },
          ]}
        >
          <Select placeholder="Select Shipper Terminal ">
            {shipperTerminals.map((terminal) => (
              <Select.Option key={terminal.id} value={terminal.id}>
                {terminal.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Card>
      <Card
        title={t(
          "initiate-quote.consignee-information",
          "Consignee Information"
        )}
      >
        <Form.Item
          name="consigneeId"
          label={t("initiate-quote.consignee", "Consignee")}
          rules={[
            {
              required: true,
              message: t(
                "initiate-quote.select-consignee",
                "Please select a consignee"
              ),
            },
          ]}
        >
          <Select placeholder="Select Consignee">
            {consignees.map((consignee) => (
              <Select.Option value={consignee.id} key={consignee.id}>
                {consignee.businessName} ({consignee.businessNumber})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="consigneeTerminalId"
          label={t("initiate-quote.consignee-Terminal", "Consignee Terminal")}
          rules={[
            {
              required: true,
              message: t(
                "initiate-quote.select-consignee-terminal",
                "Please select a consignee terminal"
              ),
            },
            {
              validator: validateConsigneeTerminal,
            },
          ]}
        >
          <Select placeholder="Select Consignee Terminal">
            {consigneeTerminals.map((consignee) => (
              <Select.Option key={consignee.id} value={consignee.id}>
                {consignee.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Card>
    </>
  );
};

export default MultiStepForm;
