import Company from "components/company/company";
import { WizardWrapper } from "pages/style";
import React, { useEffect, useState } from "react";
import { useCompanyId } from "utility/useCompanyId";

import { CompaniesApi } from "api/api";
import { Form, Spin, Button } from "antd";
import openNotificationWithIcon from "utility/notification";
import { useTranslation } from "react-i18next";
import { CompanyTypeEnum } from "../../../api";

const companiesApi = new CompaniesApi();

const CompanyProfile = ({ type }: any) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<any[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const companyId = useCompanyId();

  const fetchCompanyDatails = async () => {
    try {
      setIsLoading(true);
      const response = (
        await companiesApi.apiCompaniesIdGet({
          id: companyId,
        })
      ).data;
      setFileList(response.files as any);
      setExistingFiles(
        response.files?.map((file: any) => ({ fileName: file.fileName })) ?? []
      );

      form.setFieldsValue(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async () => {
    setIsFinished(true);
    try {
      const values = await form.getFieldsValue();
      await companiesApi.apiCompaniesIdPut({
        ...values,
        id: companyId,
        id2: companyId,
        existingFileNames: [...existingFiles]
          .filter((x) => !x.deleted)
          .map((x) => x.fileName),
        toBeDeletedFileNames: [...existingFiles]
          .filter((x) => x.deleted)
          .map((x) => x.fileName),
        companyFiles: fileList,
      });
      openNotificationWithIcon(
        "success",
        t(
          "create-company:company-edited-success",
          "Company is edited successfuly!"
        )
      );
    } catch (error) {
    } finally {
      setIsFinished(false);
    }
  };

  useEffect(() => {
    fetchCompanyDatails();
  }, []);

  return (
    <Form layout="vertical" form={form} name="transporter" onFinish={onFinish}>
      <WizardWrapper>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              height: 400,
              width: "100%",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <Company
              fileList={fileList}
              setFileList={setFileList}
              isViewMode={false}
              existingFiles={existingFiles}
              setExistingFiles={setExistingFiles}
            />
            <Button
              style={{ marginTop: 20 }}
              type="primary"
              size="large"
              onClick={() => form.submit()}
              loading={isFinished}
            >
              Save
            </Button>
          </>
        )}
      </WizardWrapper>
    </Form>
  );
};

export default CompanyProfile;
