import { useContext, useEffect, useState } from "react";
import { Form, Button, Input, Spin, Select } from "antd";
import FeatherIcon from "feather-icons-react";
import { BasicFormWrapper } from "container/styled";
import { useTranslation } from "react-i18next";
import { showServerErrors } from "utility/utility";
import openNotificationWithIcon from "utility/notification";
import { UsersApi } from "api";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
const { Option } = Select;

const usersApi = new UsersApi();

const EditProfile = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [userMe, setUserMe] = useState();
  const commonData = useContext(CommonDataContext);
  const { isLoading: loadingCommonData } = commonData;

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    const { data } = await usersApi.usersMeGet();
    form.setFieldsValue(data);
    setUserMe(data);
  };

  const handleSubmit = async ({ firstName, lastName, id }) => {
    try {
      const payload = {
        firstName,
        lastName,
        id,
        email: userMe.email,
      };
      await usersApi.usersMePut({ updateProfileCommand: payload });
      openNotificationWithIcon(
        "success",
        t(
          "profile.success.update",
          " You have successfully edited your profile information"
        )
      );
    } catch (err) {
      showServerErrors(err);
    }
  };

  return (
    <>
      {loadingCommonData ? (
        <div
          style={{
            display: "flex",
            height: 400,
            width: "100%",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <BasicFormWrapper
          style={{ width: "100%", maxWidth: 1200, marginTop: -30 }}
        >
          <Form form={form} name="editProfile" onFinish={handleSubmit}>
            <Form.Item name="id" style={{ height: 0 }}>
              <Input style={{ display: "none", height: 0 }} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={t("profile.edit:input.first-name", "First Name")}
            >
              <Input prefix={<FeatherIcon icon="user" size={14} />} />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={t("profile.edit:input.last-name", "Last Name")}
            >
              <Input prefix={<FeatherIcon icon="users" size={14} />} />
            </Form.Item>

            <Form.Item
              name="email"
              label={t("profile.edit:input.email", "Email")}
            >
              <Input disabled prefix={<FeatherIcon icon="mail" size={14} />} />
            </Form.Item>

            <div className="setting-form-actions">
              <Button size="default" htmlType="submit" type="primary">
                {t("profile.form.button", "Update Profile")}
              </Button>
            </div>
          </Form>
        </BasicFormWrapper>
      )}
    </>
  );
};

export default EditProfile;
