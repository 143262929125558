import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";

import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { UploadOutlined } from "@ant-design/icons";

import Dragger from "antd/lib/upload/Dragger";
import { BasicFormWrapper } from "container/styled";
import Heading from "components/heading/heading";
import { Button } from "components/buttons/buttons";
import { base64ToFile, downloadFilesFromBase64 } from "utility/dowloadFiles";
import { CompanyStatus } from "api/models";

const Company = ({
  isViewMode,
  fileList,
  setFileList,
  existingFiles,
  status,
  setExistingFiles,
  handleOnVerify,
  isVerifyLoading,
}: any) => {
  const { t } = useTranslation();

  // const onFinish = async (data: any) => {
  //   console.log(data);
  // };

  return (
    <BasicFormWrapper className="basic-form-inner">
      <Row justify="center">
        <Col xs={24}>
          <div className="create-account-form">
            <Heading as="h4">{t("company:heading.title", "Company")}</Heading>
            <Form.Item
              name="businessName"
              label={t("company:input.businessName", "Business Name")}
              rules={[
                {
                  required: true,
                  message: t(
                    "company:enter-business-Name",
                    "Please enter a business name"
                  ),
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                // prefix={<FeatherIcon icon="mail" size={14} />}
                placeholder={t("company:input.businessName", "Business Name")}
              />
            </Form.Item>
            <Form.Item
              name="tradeName"
              label={t("company:input.tradeName", "Trade Name")}
              rules={[
                {
                  required: true,
                  message: t(
                    "company:enter-trade-Name",
                    "Please enter trade name"
                  ),
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                // prefix={<FeatherIcon icon="mail" size={14} />}
                placeholder={t("company:input.tradeName", "Trade Name")}
              />
            </Form.Item>

            <Form.Item
              name="businessNumber"
              label={t("company:input.businessNumber", "Business Number")}
              rules={[
                {
                  required: true,
                  message: t(
                    "company:enter-business-Number",
                    "Please enter business number"
                  ),
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                // prefix={<FeatherIcon icon="mail" size={14} />}
                placeholder={t(
                  "company:input.businessNumber",
                  "Business Number"
                )}
              />
            </Form.Item>
            <Form.Item
              name="fiscalNumber"
              label={t("company:input.fiscalNumber", "Fiscal Number")}
              rules={[
                {
                  required: true,
                  message: t(
                    "company:enter-fiscal-Number",
                    "Please enter fiscal number"
                  ),
                },
              ]}
            >
              <Input
                // prefix={<FeatherIcon icon="mail" size={14} />}
                disabled={isViewMode}
                placeholder={t("company:input.fiscalNumber", "Fiscal Number")}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label={t("company:input.address", "Address")}
              rules={[
                {
                  required: true,
                  message: t("company:enter-address", "Please enter address"),
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                // prefix={<FeatherIcon icon="mail" size={14} />}
                placeholder={t("company:input.address", "Address")}
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={t("company:input.phoneNumber", "Phone Number")}
              rules={[
                {
                  required: true,
                  message: t(
                    "company:enter-phone-Number",
                    "Please enter phone number"
                  ),
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                // prefix={<FeatherIcon icon="mail" size={14} />}
                placeholder={t("company:input.phoneNumber", "Phone Number")}
              />
            </Form.Item>
            <Form.Item
              name="companyEmail"
              label={t("company:input.companyEmail", "Company Email")}
              rules={[
                {
                  required: true,
                  message: t(
                    "company:enter-company-Email",
                    "Please enter company email"
                  ),
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                prefix={<FeatherIcon icon="mail" size={14} />}
                placeholder={t("company:input.companyEmail", "Company Email")}
              />
            </Form.Item>
            {status === CompanyStatus.NUMBER_2 && (
              <div style={{ marginBottom: 20 }}>
                <Button
                  loading={isVerifyLoading}
                  disabled={isVerifyLoading}
                  type="primary"
                  size="large"
                  onClick={handleOnVerify}
                >
                  Verify
                </Button>
              </div>
            )}

            <Form.Item>
              {!isViewMode ? (
                <Dragger
                  fileList={fileList}
                  beforeUpload={(file) => {
                    setFileList((prev: any): any => [...prev, file]);
                    return false; // prevent auto uploading
                  }}
                  onRemove={(file) => {
                    if (!isViewMode) {
                      setExistingFiles((prev: any) =>
                        prev.map((x: any) => {
                          if (x.fileName.includes(file.name)) {
                            x.deleted = true;
                          }
                          return x;
                        })
                      );
                    }
                    const index = fileList.indexOf(file as never);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file(s) to this area to upload
                  </p>
                </Dragger>
              ) : (
                fileList?.length !== 0 && (
                  <Button
                    type="white"
                    onClick={() => downloadFilesFromBase64(fileList)}
                  >
                    <FeatherIcon icon="download" size="24" />
                    Download files
                  </Button>
                )
              )}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </BasicFormWrapper>
  );
};

export default Company;
