/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddCodebookCommand } from '../models';
// @ts-ignore
import { CodebookDto } from '../models';
// @ts-ignore
import { CodebookDtoPaginatedList } from '../models';
// @ts-ignore
import { UpdateCodebookCommand } from '../models';
/**
 * CodebookApi - axios parameter creator
 * @export
 */
export const CodebookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [code] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookByParentCodeGet: async (search?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, code?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Codebook/by-parentCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [codebookTypeId] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookByTypeGet: async (search?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, codebookTypeId?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Codebook/by-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (codebookTypeId !== undefined) {
                localVarQueryParameter['CodebookTypeId'] = codebookTypeId;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodebookIdDelete', 'id', id)
            const localVarPath = `/api/Codebook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodebookIdGet', 'id', id)
            const localVarPath = `/api/Codebook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCodebookCommand} [updateCodebookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookIdPut: async (id: string, updateCodebookCommand?: UpdateCodebookCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodebookIdPut', 'id', id)
            const localVarPath = `/api/Codebook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCodebookCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddCodebookCommand} [addCodebookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookPost: async (addCodebookCommand?: AddCodebookCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Codebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCodebookCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodebookApi - functional programming interface
 * @export
 */
export const CodebookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodebookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [code] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookByParentCodeGet(search?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, code?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodebookDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookByParentCodeGet(search, pageNumber, pageSize, sortBy, sortType, code, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [codebookTypeId] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookByTypeGet(search?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, codebookTypeId?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodebookDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookByTypeGet(search, pageNumber, pageSize, sortBy, sortType, codebookTypeId, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodebookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCodebookCommand} [updateCodebookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookIdPut(id: string, updateCodebookCommand?: UpdateCodebookCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookIdPut(id, updateCodebookCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddCodebookCommand} [addCodebookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookPost(addCodebookCommand?: AddCodebookCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookPost(addCodebookCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodebookApi - factory interface
 * @export
 */
export const CodebookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodebookApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [code] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookByParentCodeGet(search?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, code?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<CodebookDtoPaginatedList> {
            return localVarFp.apiCodebookByParentCodeGet(search, pageNumber, pageSize, sortBy, sortType, code, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [codebookTypeId] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookByTypeGet(search?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, codebookTypeId?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<CodebookDtoPaginatedList> {
            return localVarFp.apiCodebookByTypeGet(search, pageNumber, pageSize, sortBy, sortType, codebookTypeId, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodebookIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookIdGet(id: string, options?: any): AxiosPromise<CodebookDto> {
            return localVarFp.apiCodebookIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCodebookCommand} [updateCodebookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookIdPut(id: string, updateCodebookCommand?: UpdateCodebookCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodebookIdPut(id, updateCodebookCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddCodebookCommand} [addCodebookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookPost(addCodebookCommand?: AddCodebookCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodebookPost(addCodebookCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCodebookByParentCodeGet operation in CodebookApi.
 * @export
 * @interface CodebookApiApiCodebookByParentCodeGetRequest
 */
export interface CodebookApiApiCodebookByParentCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof CodebookApiApiCodebookByParentCodeGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiCodebookByTypeGet operation in CodebookApi.
 * @export
 * @interface CodebookApiApiCodebookByTypeGetRequest
 */
export interface CodebookApiApiCodebookByTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly codebookTypeId?: string

    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof CodebookApiApiCodebookByTypeGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiCodebookIdDelete operation in CodebookApi.
 * @export
 * @interface CodebookApiApiCodebookIdDeleteRequest
 */
export interface CodebookApiApiCodebookIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCodebookIdGet operation in CodebookApi.
 * @export
 * @interface CodebookApiApiCodebookIdGetRequest
 */
export interface CodebookApiApiCodebookIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCodebookIdPut operation in CodebookApi.
 * @export
 * @interface CodebookApiApiCodebookIdPutRequest
 */
export interface CodebookApiApiCodebookIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookApiApiCodebookIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCodebookCommand}
     * @memberof CodebookApiApiCodebookIdPut
     */
    readonly updateCodebookCommand?: UpdateCodebookCommand
}

/**
 * Request parameters for apiCodebookPost operation in CodebookApi.
 * @export
 * @interface CodebookApiApiCodebookPostRequest
 */
export interface CodebookApiApiCodebookPostRequest {
    /**
     * 
     * @type {AddCodebookCommand}
     * @memberof CodebookApiApiCodebookPost
     */
    readonly addCodebookCommand?: AddCodebookCommand
}

/**
 * CodebookApi - object-oriented interface
 * @export
 * @class CodebookApi
 * @extends {BaseAPI}
 */
export class CodebookApi extends BaseAPI {
    /**
     * 
     * @param {CodebookApiApiCodebookByParentCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public apiCodebookByParentCodeGet(requestParameters: CodebookApiApiCodebookByParentCodeGetRequest = {}, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).apiCodebookByParentCodeGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.code, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookApiApiCodebookByTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public apiCodebookByTypeGet(requestParameters: CodebookApiApiCodebookByTypeGetRequest = {}, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).apiCodebookByTypeGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.codebookTypeId, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookApiApiCodebookIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public apiCodebookIdDelete(requestParameters: CodebookApiApiCodebookIdDeleteRequest, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).apiCodebookIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookApiApiCodebookIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public apiCodebookIdGet(requestParameters: CodebookApiApiCodebookIdGetRequest, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).apiCodebookIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookApiApiCodebookIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public apiCodebookIdPut(requestParameters: CodebookApiApiCodebookIdPutRequest, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).apiCodebookIdPut(requestParameters.id, requestParameters.updateCodebookCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookApiApiCodebookPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public apiCodebookPost(requestParameters: CodebookApiApiCodebookPostRequest = {}, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).apiCodebookPost(requestParameters.addCodebookCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
