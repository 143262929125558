import { useState, useEffect } from "react";
import { EquipmentApi } from "api/api";
import { EquipmentProps } from "../equipmentDataTableColumns";

export const useEquipmentsFetch = ({ companyId }: EquipmentProps) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
    companyId,
  });

  const fetchEquipments = async () => {
    setIsLoading(true);
    try {
      const response = (await new EquipmentApi().apiEquipmentsGet(query)).data;
      setData(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEquipments();
  }, [query]);

  return { data, isLoading, fetchEquipments, query, setQuery };
};
