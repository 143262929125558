import React from "react";
import { Table } from "antd";
import { TableWrapper } from "container/styled";
import { useBidColumns } from "./bidDataTableColumns";
import openNotificationWithIcon from "utility/notification";
import { BidsApi } from "api/api";
import { BidType } from "./bidDataTableColumns";
import { TFunction } from "i18next";

interface BidsTableProps {
  data: any;
  isLoading: boolean;
  setQuery: (query: any) => void;
  fetchBids: () => void;
  statuses: any[];
  handleStatusChange: (bidId: string, statusId: string) => Promise<void>;
  handleViewClick: (item: BidType) => void;
  handleEditClick: (item: BidType) => void;
  handleConfirmClick: (item: BidType) => void;
  confirmedBidId: string | null;
  t: TFunction<"translation", undefined>;
  selectedBidId: any;
}

const BidsTable: React.FC<BidsTableProps> = ({
  data,
  isLoading,
  setQuery,
  fetchBids,
  statuses,
  handleStatusChange,
  handleViewClick,
  handleEditClick,
  handleConfirmClick,
  confirmedBidId,
  selectedBidId,
  t,
}) => {
  const columns = useBidColumns({
    statuses: statuses,
    handleStatusChange,
    handleViewClick,
    handleEditClick,
    handleDeleteClick: async (item: BidType) => {
      try {
        await new BidsApi().apiBidsIdDelete({ id: item.id });
        fetchBids();
        openNotificationWithIcon(
          "success",
          t("bid:driver-deleted-success", "Bid deleted successfully!")
        );
      } catch (error) {
        console.error("Error deleting bid:", error);
      }
    },
    handleConfirmClick,
    confirmedBidId,
    selectedBidId,
  });

  return (
    <TableWrapper className="table-responsive">
      <Table
        loading={isLoading}
        dataSource={data?.items}
        columns={columns}
        rowKey="id"
        pagination={{
          current: data?.pageIndex,
          total: data?.totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["10", "50", "100", "1000"],
          onChange: (pageNumber, pageSize) =>
            setQuery((prev: any) => ({ ...prev, pageNumber, pageSize })),
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </TableWrapper>
  );
};

export default BidsTable;
