/* tslint:disable */
/* eslint-disable */
/**
 * LogisticxApp PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddNewCantonCommand } from '../models';
// @ts-ignore
import { CantonDto } from '../models';
// @ts-ignore
import { CantonDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { UpdateCantonCommand } from '../models';
/**
 * CantonsApi - axios parameter creator
 * @export
 */
export const CantonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cantons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCantonsIdDelete', 'id', id)
            const localVarPath = `/api/cantons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCantonsIdGet', 'id', id)
            const localVarPath = `/api/cantons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCantonCommand} [updateCantonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsIdPut: async (id: string, updateCantonCommand?: UpdateCantonCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCantonsIdPut', 'id', id)
            const localVarPath = `/api/cantons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCantonCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddNewCantonCommand} [addNewCantonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsPost: async (addNewCantonCommand?: AddNewCantonCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cantons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNewCantonCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CantonsApi - functional programming interface
 * @export
 */
export const CantonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CantonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCantonsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CantonDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCantonsGet(pageNumber, pageSize, sortBy, sortType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCantonsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCantonsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCantonsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CantonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCantonsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCantonCommand} [updateCantonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCantonsIdPut(id: string, updateCantonCommand?: UpdateCantonCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCantonsIdPut(id, updateCantonCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddNewCantonCommand} [addNewCantonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCantonsPost(addNewCantonCommand?: AddNewCantonCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCantonsPost(addNewCantonCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CantonsApi - factory interface
 * @export
 */
export const CantonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CantonsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: any): AxiosPromise<CantonDtoOrdinalPaginatedList> {
            return localVarFp.apiCantonsGet(pageNumber, pageSize, sortBy, sortType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCantonsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsIdGet(id: string, options?: any): AxiosPromise<CantonDto> {
            return localVarFp.apiCantonsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCantonCommand} [updateCantonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsIdPut(id: string, updateCantonCommand?: UpdateCantonCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCantonsIdPut(id, updateCantonCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddNewCantonCommand} [addNewCantonCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCantonsPost(addNewCantonCommand?: AddNewCantonCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCantonsPost(addNewCantonCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCantonsGet operation in CantonsApi.
 * @export
 * @interface CantonsApiApiCantonsGetRequest
 */
export interface CantonsApiApiCantonsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CantonsApiApiCantonsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CantonsApiApiCantonsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CantonsApiApiCantonsGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CantonsApiApiCantonsGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CantonsApiApiCantonsGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiCantonsIdDelete operation in CantonsApi.
 * @export
 * @interface CantonsApiApiCantonsIdDeleteRequest
 */
export interface CantonsApiApiCantonsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CantonsApiApiCantonsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCantonsIdGet operation in CantonsApi.
 * @export
 * @interface CantonsApiApiCantonsIdGetRequest
 */
export interface CantonsApiApiCantonsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CantonsApiApiCantonsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCantonsIdPut operation in CantonsApi.
 * @export
 * @interface CantonsApiApiCantonsIdPutRequest
 */
export interface CantonsApiApiCantonsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CantonsApiApiCantonsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCantonCommand}
     * @memberof CantonsApiApiCantonsIdPut
     */
    readonly updateCantonCommand?: UpdateCantonCommand
}

/**
 * Request parameters for apiCantonsPost operation in CantonsApi.
 * @export
 * @interface CantonsApiApiCantonsPostRequest
 */
export interface CantonsApiApiCantonsPostRequest {
    /**
     * 
     * @type {AddNewCantonCommand}
     * @memberof CantonsApiApiCantonsPost
     */
    readonly addNewCantonCommand?: AddNewCantonCommand
}

/**
 * CantonsApi - object-oriented interface
 * @export
 * @class CantonsApi
 * @extends {BaseAPI}
 */
export class CantonsApi extends BaseAPI {
    /**
     * 
     * @param {CantonsApiApiCantonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CantonsApi
     */
    public apiCantonsGet(requestParameters: CantonsApiApiCantonsGetRequest = {}, options?: AxiosRequestConfig) {
        return CantonsApiFp(this.configuration).apiCantonsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CantonsApiApiCantonsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CantonsApi
     */
    public apiCantonsIdDelete(requestParameters: CantonsApiApiCantonsIdDeleteRequest, options?: AxiosRequestConfig) {
        return CantonsApiFp(this.configuration).apiCantonsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CantonsApiApiCantonsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CantonsApi
     */
    public apiCantonsIdGet(requestParameters: CantonsApiApiCantonsIdGetRequest, options?: AxiosRequestConfig) {
        return CantonsApiFp(this.configuration).apiCantonsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CantonsApiApiCantonsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CantonsApi
     */
    public apiCantonsIdPut(requestParameters: CantonsApiApiCantonsIdPutRequest, options?: AxiosRequestConfig) {
        return CantonsApiFp(this.configuration).apiCantonsIdPut(requestParameters.id, requestParameters.updateCantonCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CantonsApiApiCantonsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CantonsApi
     */
    public apiCantonsPost(requestParameters: CantonsApiApiCantonsPostRequest = {}, options?: AxiosRequestConfig) {
        return CantonsApiFp(this.configuration).apiCantonsPost(requestParameters.addNewCantonCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
