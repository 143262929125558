/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ModuleFunctionalitiesDto } from '../models';
/**
 * ModulesApi - axios parameter creator
 * @export
 */
export const ModulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userGroupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesGet: async (userGroupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userGroupName !== undefined) {
                localVarQueryParameter['UserGroupName'] = userGroupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModulesApi - functional programming interface
 * @export
 */
export const ModulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userGroupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesGet(userGroupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleFunctionalitiesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesGet(userGroupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModulesApi - factory interface
 * @export
 */
export const ModulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModulesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userGroupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesGet(userGroupName?: string, options?: any): AxiosPromise<Array<ModuleFunctionalitiesDto>> {
            return localVarFp.modulesGet(userGroupName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for modulesGet operation in ModulesApi.
 * @export
 * @interface ModulesApiModulesGetRequest
 */
export interface ModulesApiModulesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ModulesApiModulesGet
     */
    readonly userGroupName?: string
}

/**
 * ModulesApi - object-oriented interface
 * @export
 * @class ModulesApi
 * @extends {BaseAPI}
 */
export class ModulesApi extends BaseAPI {
    /**
     * 
     * @param {ModulesApiModulesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesGet(requestParameters: ModulesApiModulesGetRequest = {}, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesGet(requestParameters.userGroupName, options).then((request) => request(this.axios, this.basePath));
    }
}
