/* tslint:disable */
/* eslint-disable */
/**
 * LogisticxApp PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddNewMunicipalityCommand } from '../models';
// @ts-ignore
import { MunicipalityDto } from '../models';
// @ts-ignore
import { MunicipalityDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { UpdateMunicipalityCommand } from '../models';
/**
 * MunicipalitiesApi - axios parameter creator
 * @export
 */
export const MunicipalitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/municipalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMunicipalitiesIdDelete', 'id', id)
            const localVarPath = `/api/municipalities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMunicipalitiesIdGet', 'id', id)
            const localVarPath = `/api/municipalities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddNewMunicipalityCommand} [addNewMunicipalityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesPost: async (addNewMunicipalityCommand?: AddNewMunicipalityCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/municipalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNewMunicipalityCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMunicipalityCommand} [updateMunicipalityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesPut: async (updateMunicipalityCommand?: UpdateMunicipalityCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/municipalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMunicipalityCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MunicipalitiesApi - functional programming interface
 * @export
 */
export const MunicipalitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MunicipalitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMunicipalitiesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MunicipalityDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMunicipalitiesGet(pageNumber, pageSize, sortBy, sortType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMunicipalitiesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMunicipalitiesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMunicipalitiesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MunicipalityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMunicipalitiesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddNewMunicipalityCommand} [addNewMunicipalityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMunicipalitiesPost(addNewMunicipalityCommand?: AddNewMunicipalityCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMunicipalitiesPost(addNewMunicipalityCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateMunicipalityCommand} [updateMunicipalityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMunicipalitiesPut(updateMunicipalityCommand?: UpdateMunicipalityCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMunicipalitiesPut(updateMunicipalityCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MunicipalitiesApi - factory interface
 * @export
 */
export const MunicipalitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MunicipalitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: any): AxiosPromise<MunicipalityDtoOrdinalPaginatedList> {
            return localVarFp.apiMunicipalitiesGet(pageNumber, pageSize, sortBy, sortType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMunicipalitiesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesIdGet(id: string, options?: any): AxiosPromise<MunicipalityDto> {
            return localVarFp.apiMunicipalitiesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddNewMunicipalityCommand} [addNewMunicipalityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesPost(addNewMunicipalityCommand?: AddNewMunicipalityCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiMunicipalitiesPost(addNewMunicipalityCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMunicipalityCommand} [updateMunicipalityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMunicipalitiesPut(updateMunicipalityCommand?: UpdateMunicipalityCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiMunicipalitiesPut(updateMunicipalityCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiMunicipalitiesGet operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiApiMunicipalitiesGetRequest
 */
export interface MunicipalitiesApiApiMunicipalitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MunicipalitiesApiApiMunicipalitiesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof MunicipalitiesApiApiMunicipalitiesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof MunicipalitiesApiApiMunicipalitiesGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof MunicipalitiesApiApiMunicipalitiesGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof MunicipalitiesApiApiMunicipalitiesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiMunicipalitiesIdDelete operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiApiMunicipalitiesIdDeleteRequest
 */
export interface MunicipalitiesApiApiMunicipalitiesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MunicipalitiesApiApiMunicipalitiesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiMunicipalitiesIdGet operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiApiMunicipalitiesIdGetRequest
 */
export interface MunicipalitiesApiApiMunicipalitiesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MunicipalitiesApiApiMunicipalitiesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiMunicipalitiesPost operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiApiMunicipalitiesPostRequest
 */
export interface MunicipalitiesApiApiMunicipalitiesPostRequest {
    /**
     * 
     * @type {AddNewMunicipalityCommand}
     * @memberof MunicipalitiesApiApiMunicipalitiesPost
     */
    readonly addNewMunicipalityCommand?: AddNewMunicipalityCommand
}

/**
 * Request parameters for apiMunicipalitiesPut operation in MunicipalitiesApi.
 * @export
 * @interface MunicipalitiesApiApiMunicipalitiesPutRequest
 */
export interface MunicipalitiesApiApiMunicipalitiesPutRequest {
    /**
     * 
     * @type {UpdateMunicipalityCommand}
     * @memberof MunicipalitiesApiApiMunicipalitiesPut
     */
    readonly updateMunicipalityCommand?: UpdateMunicipalityCommand
}

/**
 * MunicipalitiesApi - object-oriented interface
 * @export
 * @class MunicipalitiesApi
 * @extends {BaseAPI}
 */
export class MunicipalitiesApi extends BaseAPI {
    /**
     * 
     * @param {MunicipalitiesApiApiMunicipalitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public apiMunicipalitiesGet(requestParameters: MunicipalitiesApiApiMunicipalitiesGetRequest = {}, options?: AxiosRequestConfig) {
        return MunicipalitiesApiFp(this.configuration).apiMunicipalitiesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MunicipalitiesApiApiMunicipalitiesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public apiMunicipalitiesIdDelete(requestParameters: MunicipalitiesApiApiMunicipalitiesIdDeleteRequest, options?: AxiosRequestConfig) {
        return MunicipalitiesApiFp(this.configuration).apiMunicipalitiesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MunicipalitiesApiApiMunicipalitiesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public apiMunicipalitiesIdGet(requestParameters: MunicipalitiesApiApiMunicipalitiesIdGetRequest, options?: AxiosRequestConfig) {
        return MunicipalitiesApiFp(this.configuration).apiMunicipalitiesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MunicipalitiesApiApiMunicipalitiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public apiMunicipalitiesPost(requestParameters: MunicipalitiesApiApiMunicipalitiesPostRequest = {}, options?: AxiosRequestConfig) {
        return MunicipalitiesApiFp(this.configuration).apiMunicipalitiesPost(requestParameters.addNewMunicipalityCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MunicipalitiesApiApiMunicipalitiesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MunicipalitiesApi
     */
    public apiMunicipalitiesPut(requestParameters: MunicipalitiesApiApiMunicipalitiesPutRequest = {}, options?: AxiosRequestConfig) {
        return MunicipalitiesApiFp(this.configuration).apiMunicipalitiesPut(requestParameters.updateMunicipalityCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
