import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FormListFieldData } from "antd/lib/form/FormList";
import { TFunction } from "i18next";
import TextArea from "antd/lib/input/TextArea";

interface CorridorsComponentProps {
  fields: FormListFieldData[];
  remove: (index: number | number[]) => void;
  t: TFunction<"translation", undefined>;
}

const CorridorsComponent: React.FC<CorridorsComponentProps> = ({
  fields,
  remove,
  t,
}) => {
  return (
    <>
      {fields.map(({ key, name, ...restField }, index) => (
        <div style={{ position: "relative" }} key={key}>
          <Form.Item>
            <Button
              icon={<DeleteOutlined style={{ color: "#8a0907" }} />}
              onClick={() => remove(index)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            ></Button>
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "description"]}
            label={t("corridor.corridor-location", "Corridor Location")}
            rules={[
              {
                required: true,
                message: t(
                  "corridor.enter.corridor-location",
                  "Please enter the corridor location"
                ),
              },
            ]}
          >
            <Input placeholder="Enter corridor location" />
          </Form.Item>

          <Form.Item
            {...restField}
            name={[name, "specialRequirements"]}
            label={t("corridor.special-requirements", "Special Requirements")}
          >
            <TextArea placeholder="Enter detailed description" rows={4} />
          </Form.Item>
          <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
        </div>
      ))}
    </>
  );
};

export default CorridorsComponent;
