/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { UserFilterType } from '../models';
/**
 * DriversApi - axios parameter creator
 * @export
 */
export const DriversApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [roleId] 
         * @param {string} [companyId] 
         * @param {string} [userGroup] 
         * @param {string} [search] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversCompaniesGet: async (pageNumber?: number, pageSize?: number, roleId?: string, companyId?: string, userGroup?: string, search?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/drivers/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['RoleId'] = roleId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }

            if (userGroup !== undefined) {
                localVarQueryParameter['UserGroup'] = userGroup;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['FilterType'] = filterType;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDriversIdDelete', 'id', id)
            const localVarPath = `/api/drivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [languageId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [phoneNumber] 
         * @param {string} [comments] 
         * @param {Array<string>} [userGroups] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [userFiles] 
         * @param {string} [issuingAuthority] 
         * @param {Array<string>} [entitledCategoriesOfDrive] 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversIdPut: async (id: string, id2?: string, firstName?: string, lastName?: string, languageId?: string, placeOfBirth?: string, address?: string, personalNumber?: string, phoneNumber?: string, comments?: string, userGroups?: Array<string>, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, userFiles?: Array<File>, issuingAuthority?: string, entitledCategoriesOfDrive?: Array<string>, expirationDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDriversIdPut', 'id', id)
            const localVarPath = `/api/drivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (languageId !== undefined) { 
                localVarFormParams.append('LanguageId', languageId as any);
            }
    
            if (placeOfBirth !== undefined) { 
                localVarFormParams.append('PlaceOfBirth', placeOfBirth as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (personalNumber !== undefined) { 
                localVarFormParams.append('PersonalNumber', personalNumber as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
                if (userGroups) {
                userGroups.forEach((element) => {
                    localVarFormParams.append('UserGroups', element as any);
                })
            }

                if (existingFileNames) {
                existingFileNames.forEach((element) => {
                    localVarFormParams.append('ExistingFileNames', element as any);
                })
            }

                if (toBeDeletedFileNames) {
                toBeDeletedFileNames.forEach((element) => {
                    localVarFormParams.append('ToBeDeletedFileNames', element as any);
                })
            }

                if (userFiles) {
                userFiles.forEach((element) => {
                    localVarFormParams.append('UserFiles', element as any);
                })
            }

    
            if (issuingAuthority !== undefined) { 
                localVarFormParams.append('IssuingAuthority', issuingAuthority as any);
            }
                if (entitledCategoriesOfDrive) {
                entitledCategoriesOfDrive.forEach((element) => {
                    localVarFormParams.append('EntitledCategoriesOfDrive', element as any);
                })
            }

    
            if (expirationDate !== undefined) { 
                localVarFormParams.append('ExpirationDate', expirationDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [defaultLanguage] 
         * @param {string} [languageId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [phoneNumber] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [createdById] 
         * @param {string} [companyId] 
         * @param {string} [comments] 
         * @param {Array<string>} [userGroups] 
         * @param {Array<File>} [userFiles] 
         * @param {string} [issuingAuthority] 
         * @param {Array<string>} [entitledCategoriesOfDrive] 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversPost: async (firstName?: string, lastName?: string, email?: string, defaultLanguage?: string, languageId?: string, placeOfBirth?: string, phoneNumber?: string, address?: string, personalNumber?: string, createdById?: string, companyId?: string, comments?: string, userGroups?: Array<string>, userFiles?: Array<File>, issuingAuthority?: string, entitledCategoriesOfDrive?: Array<string>, expirationDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/drivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('Email', email as any);
            }
    
            if (defaultLanguage !== undefined) { 
                localVarFormParams.append('DefaultLanguage', defaultLanguage as any);
            }
    
            if (languageId !== undefined) { 
                localVarFormParams.append('LanguageId', languageId as any);
            }
    
            if (placeOfBirth !== undefined) { 
                localVarFormParams.append('PlaceOfBirth', placeOfBirth as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (personalNumber !== undefined) { 
                localVarFormParams.append('PersonalNumber', personalNumber as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (companyId !== undefined) { 
                localVarFormParams.append('CompanyId', companyId as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
                if (userGroups) {
                userGroups.forEach((element) => {
                    localVarFormParams.append('UserGroups', element as any);
                })
            }

                if (userFiles) {
                userFiles.forEach((element) => {
                    localVarFormParams.append('UserFiles', element as any);
                })
            }

    
            if (issuingAuthority !== undefined) { 
                localVarFormParams.append('IssuingAuthority', issuingAuthority as any);
            }
                if (entitledCategoriesOfDrive) {
                entitledCategoriesOfDrive.forEach((element) => {
                    localVarFormParams.append('EntitledCategoriesOfDrive', element as any);
                })
            }

    
            if (expirationDate !== undefined) { 
                localVarFormParams.append('ExpirationDate', expirationDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriversApi - functional programming interface
 * @export
 */
export const DriversApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriversApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [roleId] 
         * @param {string} [companyId] 
         * @param {string} [userGroup] 
         * @param {string} [search] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDriversCompaniesGet(pageNumber?: number, pageSize?: number, roleId?: string, companyId?: string, userGroup?: string, search?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDriversCompaniesGet(pageNumber, pageSize, roleId, companyId, userGroup, search, filterType, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDriversIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDriversIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [languageId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [phoneNumber] 
         * @param {string} [comments] 
         * @param {Array<string>} [userGroups] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [userFiles] 
         * @param {string} [issuingAuthority] 
         * @param {Array<string>} [entitledCategoriesOfDrive] 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDriversIdPut(id: string, id2?: string, firstName?: string, lastName?: string, languageId?: string, placeOfBirth?: string, address?: string, personalNumber?: string, phoneNumber?: string, comments?: string, userGroups?: Array<string>, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, userFiles?: Array<File>, issuingAuthority?: string, entitledCategoriesOfDrive?: Array<string>, expirationDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDriversIdPut(id, id2, firstName, lastName, languageId, placeOfBirth, address, personalNumber, phoneNumber, comments, userGroups, existingFileNames, toBeDeletedFileNames, userFiles, issuingAuthority, entitledCategoriesOfDrive, expirationDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [defaultLanguage] 
         * @param {string} [languageId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [phoneNumber] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [createdById] 
         * @param {string} [companyId] 
         * @param {string} [comments] 
         * @param {Array<string>} [userGroups] 
         * @param {Array<File>} [userFiles] 
         * @param {string} [issuingAuthority] 
         * @param {Array<string>} [entitledCategoriesOfDrive] 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDriversPost(firstName?: string, lastName?: string, email?: string, defaultLanguage?: string, languageId?: string, placeOfBirth?: string, phoneNumber?: string, address?: string, personalNumber?: string, createdById?: string, companyId?: string, comments?: string, userGroups?: Array<string>, userFiles?: Array<File>, issuingAuthority?: string, entitledCategoriesOfDrive?: Array<string>, expirationDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDriversPost(firstName, lastName, email, defaultLanguage, languageId, placeOfBirth, phoneNumber, address, personalNumber, createdById, companyId, comments, userGroups, userFiles, issuingAuthority, entitledCategoriesOfDrive, expirationDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DriversApi - factory interface
 * @export
 */
export const DriversApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriversApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [roleId] 
         * @param {string} [companyId] 
         * @param {string} [userGroup] 
         * @param {string} [search] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversCompaniesGet(pageNumber?: number, pageSize?: number, roleId?: string, companyId?: string, userGroup?: string, search?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<UserDtoOrdinalPaginatedList> {
            return localVarFp.apiDriversCompaniesGet(pageNumber, pageSize, roleId, companyId, userGroup, search, filterType, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDriversIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [languageId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [phoneNumber] 
         * @param {string} [comments] 
         * @param {Array<string>} [userGroups] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [userFiles] 
         * @param {string} [issuingAuthority] 
         * @param {Array<string>} [entitledCategoriesOfDrive] 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversIdPut(id: string, id2?: string, firstName?: string, lastName?: string, languageId?: string, placeOfBirth?: string, address?: string, personalNumber?: string, phoneNumber?: string, comments?: string, userGroups?: Array<string>, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, userFiles?: Array<File>, issuingAuthority?: string, entitledCategoriesOfDrive?: Array<string>, expirationDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDriversIdPut(id, id2, firstName, lastName, languageId, placeOfBirth, address, personalNumber, phoneNumber, comments, userGroups, existingFileNames, toBeDeletedFileNames, userFiles, issuingAuthority, entitledCategoriesOfDrive, expirationDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [defaultLanguage] 
         * @param {string} [languageId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [phoneNumber] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [createdById] 
         * @param {string} [companyId] 
         * @param {string} [comments] 
         * @param {Array<string>} [userGroups] 
         * @param {Array<File>} [userFiles] 
         * @param {string} [issuingAuthority] 
         * @param {Array<string>} [entitledCategoriesOfDrive] 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDriversPost(firstName?: string, lastName?: string, email?: string, defaultLanguage?: string, languageId?: string, placeOfBirth?: string, phoneNumber?: string, address?: string, personalNumber?: string, createdById?: string, companyId?: string, comments?: string, userGroups?: Array<string>, userFiles?: Array<File>, issuingAuthority?: string, entitledCategoriesOfDrive?: Array<string>, expirationDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDriversPost(firstName, lastName, email, defaultLanguage, languageId, placeOfBirth, phoneNumber, address, personalNumber, createdById, companyId, comments, userGroups, userFiles, issuingAuthority, entitledCategoriesOfDrive, expirationDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDriversCompaniesGet operation in DriversApi.
 * @export
 * @interface DriversApiApiDriversCompaniesGetRequest
 */
export interface DriversApiApiDriversCompaniesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly roleId?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly companyId?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly userGroup?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly search?: string

    /**
     * 
     * @type {UserFilterType}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly filterType?: UserFilterType

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof DriversApiApiDriversCompaniesGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiDriversIdDelete operation in DriversApi.
 * @export
 * @interface DriversApiApiDriversIdDeleteRequest
 */
export interface DriversApiApiDriversIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiDriversIdPut operation in DriversApi.
 * @export
 * @interface DriversApiApiDriversIdPutRequest
 */
export interface DriversApiApiDriversIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly id2?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly languageId?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly placeOfBirth?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly personalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly comments?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly userGroups?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly existingFileNames?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly toBeDeletedFileNames?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly userFiles?: Array<File>

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly issuingAuthority?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly entitledCategoriesOfDrive?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversIdPut
     */
    readonly expirationDate?: string
}

/**
 * Request parameters for apiDriversPost operation in DriversApi.
 * @export
 * @interface DriversApiApiDriversPostRequest
 */
export interface DriversApiApiDriversPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly defaultLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly languageId?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly placeOfBirth?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly personalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly createdById?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly companyId?: string

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly comments?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DriversApiApiDriversPost
     */
    readonly userGroups?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof DriversApiApiDriversPost
     */
    readonly userFiles?: Array<File>

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly issuingAuthority?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DriversApiApiDriversPost
     */
    readonly entitledCategoriesOfDrive?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DriversApiApiDriversPost
     */
    readonly expirationDate?: string
}

/**
 * DriversApi - object-oriented interface
 * @export
 * @class DriversApi
 * @extends {BaseAPI}
 */
export class DriversApi extends BaseAPI {
    /**
     * 
     * @param {DriversApiApiDriversCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public apiDriversCompaniesGet(requestParameters: DriversApiApiDriversCompaniesGetRequest = {}, options?: AxiosRequestConfig) {
        return DriversApiFp(this.configuration).apiDriversCompaniesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.roleId, requestParameters.companyId, requestParameters.userGroup, requestParameters.search, requestParameters.filterType, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DriversApiApiDriversIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public apiDriversIdDelete(requestParameters: DriversApiApiDriversIdDeleteRequest, options?: AxiosRequestConfig) {
        return DriversApiFp(this.configuration).apiDriversIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DriversApiApiDriversIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public apiDriversIdPut(requestParameters: DriversApiApiDriversIdPutRequest, options?: AxiosRequestConfig) {
        return DriversApiFp(this.configuration).apiDriversIdPut(requestParameters.id, requestParameters.id2, requestParameters.firstName, requestParameters.lastName, requestParameters.languageId, requestParameters.placeOfBirth, requestParameters.address, requestParameters.personalNumber, requestParameters.phoneNumber, requestParameters.comments, requestParameters.userGroups, requestParameters.existingFileNames, requestParameters.toBeDeletedFileNames, requestParameters.userFiles, requestParameters.issuingAuthority, requestParameters.entitledCategoriesOfDrive, requestParameters.expirationDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DriversApiApiDriversPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public apiDriversPost(requestParameters: DriversApiApiDriversPostRequest = {}, options?: AxiosRequestConfig) {
        return DriversApiFp(this.configuration).apiDriversPost(requestParameters.firstName, requestParameters.lastName, requestParameters.email, requestParameters.defaultLanguage, requestParameters.languageId, requestParameters.placeOfBirth, requestParameters.phoneNumber, requestParameters.address, requestParameters.personalNumber, requestParameters.createdById, requestParameters.companyId, requestParameters.comments, requestParameters.userGroups, requestParameters.userFiles, requestParameters.issuingAuthority, requestParameters.entitledCategoriesOfDrive, requestParameters.expirationDate, options).then((request) => request(this.axios, this.basePath));
    }
}
