import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Spin, Select, Row, Col } from "antd";
import { Button } from "components/buttons/buttons";
import { Modal } from "components/modals/antd-modals";
import { UploadOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import {
  CompanyDto,
  CompanyStatus,
  CompanyTypeEnum,
  DealDto,
  UserDto,
} from "api/models";
import {
  BidsApi,
  CompaniesApi,
  DealsApi,
  DriversApi,
  EquipmentApi,
} from "api/api";
import openNotificationWithIcon from "utility/notification";
import { EquipmentDto } from "api/models/equipment-dto";
import { ModalMode } from "hooks/useModalState";
import { BidProps } from "./bidDataTableColumns";
import Dragger from "antd/lib/upload/Dragger";
import { base64ToFile, downloadFilesFromBase64 } from "utility/dowloadFiles";
import { useCompanyId } from "utility/useCompanyId";
import { getRole } from "utility/decode-jwt";
import { PredefinedRoles } from "constants/constants";

const companiesApi = new CompaniesApi();
const bidsApi = new BidsApi();
const driversApi = new DriversApi();
const equipmentsApi = new EquipmentApi();
const dealsApi = new DealsApi();

const Bid: React.FC<BidProps> = ({
  t,
  onSave,
  isVisible,
  onHide,
  mode,
  bidToEdit,
  dealId,
}) => {
  const [form] = Form.useForm();
  const [transporters, setTransporters] = useState<CompanyDto[]>([]);
  const [transporterId, setTransporterId] = useState<any>("");
  const [equipments, setEquipments] = useState<EquipmentDto[]>([]);
  const [drivers, setDrivers] = useState<UserDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDealLoaded, setIsDealLoaded] = useState<boolean>(false);

  const [fileList, setFileList] = useState<any[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [controlledTemperature, setControlledTemperature] =
    useState<any>(false);
  const [allowsADR, setAllowsADR] = useState<any>(false);
  const [location, setLocation] = useState<any>("");
  const [dealDetails, setDealDetails] = useState<DealDto>();

  const readOnlyMode = mode === ModalMode.VIEW;
  const updateMode = mode === ModalMode.UPSERT;

  const roles = getRole();
  const isFreightForwarderRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.FREIGHT_FORWARDER)
    : roles === PredefinedRoles.FREIGHT_FORWARDER;

  const fetchDealsId = useCallback(async () => {
    if (dealId) {
      try {
        setIsLoading(true);
        const response = await dealsApi.apiDealsIdGet({
          id: dealId,
        });
        setControlledTemperature(response.data.controlledTemperature);
        setAllowsADR(response.data.isADR);
        setLocation(response.data.pickLocation);
        setDealDetails(response.data);
        setIsDealLoaded(true);
        console.log(response.data.bids);
      } catch (error) {
        console.error("Error fetching deal details:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [dealId]);

  const fetchBidDetails = useCallback(async () => {
    if (!bidToEdit) return;

    try {
      setIsLoading(true);
      const { data } = await bidsApi.apiBidsIdGet({ id: bidToEdit.id });

      setExistingFiles(
        data?.files?.map((file) => ({
          fileName: file.fileName,
          deleted: false,
        })) ?? []
      );
      setFileList(
        readOnlyMode ? data?.files ?? [] : data?.files?.map(base64ToFile) ?? []
      );

      setTransporterId(data.transporterId);
      form.setFieldsValue({
        ...data,
        transporterId: {
          value: data.transporterId,
          label: data.transporterName,
        },
      });
    } catch (error) {
      console.error("Error fetching bid details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [bidToEdit, form, readOnlyMode]);

  const handleOnSave = useCallback(async () => {
    setIsBtnLoading(true);

    try {
      const values = await form.validateFields();
      if (bidToEdit) {
        await bidsApi.apiBidsPut({
          ...values,
          id: bidToEdit.id,
          existingFileNames: [...existingFiles]
            .filter((x) => !x.deleted)
            .map((x) => x.fileName),
          toBeDeletedFileNames: [...existingFiles]
            .filter((x) => x.deleted)
            .map((x) => x.fileName),
          bidFiles: fileList,
          dealId: dealId,
          transporterId: transporterId,
        });
        openNotificationWithIcon(
          "success",
          t("bid:bid-edited-success", "Bid is edited successfully!")
        );
      } else {
        await bidsApi.apiBidsPost({
          ...values,
          bidFiles: fileList,
          dealId: dealId,
          transporterId: transporterId,
        });

        openNotificationWithIcon(
          "success",
          t("bid:driver-created-success", "Bid is created successfully!")
        );
      }

      if (onHide && onSave) {
        onHide();
        onSave("" as any);
      }
    } catch (err) {
      console.error("Error saving bid:", err);
    } finally {
      setIsBtnLoading(false);
    }
  }, [
    form,
    bidToEdit,
    dealId,
    fileList,
    existingFiles,
    onHide,
    onSave,
    transporterId,
    t,
  ]);

  const fetchTransporters = useCallback(async () => {
    if (!isDealLoaded) return;

    try {
      const { data } = await bidsApi.apiBidsAvailableTransportersGet({
        controlledTemperature,
        allowsADR,
        location,
        pageSize: -1,
      });

      const existingTransporterIds = new Set(
        dealDetails?.bids?.map((bid) => bid.transporterId) || []
      );

      let filteredTransporters = ((data.items as CompanyDto[]) || []).filter(
        (transporter) => !existingTransporterIds.has(transporter.id)
      );
      if (bidToEdit && bidToEdit.transporterId) {
        const currentTransporter = data.items?.find(
          (transporter) => transporter.id === bidToEdit.transporterId
        );
        if (currentTransporter) {
          filteredTransporters = [currentTransporter, ...filteredTransporters];
        }
      }
      setTransporters(filteredTransporters);
    } catch (error) {
      console.error("Error fetching Transporters:", error);
    }
  }, [
    isDealLoaded,
    controlledTemperature,
    allowsADR,
    location,
    dealDetails,
    bidToEdit,
  ]);

  useEffect(() => {
    const fetchEquipments = async () => {
      if (!transporterId) return;
      try {
        const response: any = await equipmentsApi.apiEquipmentsGet({
          companyId: transporterId,
          allowsADR,
          controlledTemperature,
        });

        if (response.data.items) {
          setEquipments(response.data.items as any);
        }
      } catch (error) {
        console.log("Error fetching equipments:", error);
      }
    };

    const fetchDrivers = async () => {
      if (!transporterId) return;
      try {
        const response = await driversApi.apiDriversCompaniesGet({
          companyId: transporterId,
        });

        var drivers = response.data.items;

        if (drivers) {
          setDrivers(drivers);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.log("Error fetching drivers:", error);
      }
    };

    fetchEquipments();
    fetchDrivers();
  }, [transporterId, allowsADR, controlledTemperature]);

  useEffect(() => {
    fetchDealsId();
  }, [fetchDealsId]);

  useEffect(() => {
    if (bidToEdit) {
      fetchBidDetails();
    }
  }, [bidToEdit, fetchBidDetails]);

  useEffect(() => {
    if (isVisible && !readOnlyMode && !updateMode) {
      form.resetFields();
      setFileList([]);
      setExistingFiles([]);
    }
  }, [isVisible, readOnlyMode, updateMode, form]);

  useEffect(() => {
    if (isVisible && dealId && isFreightForwarderRole && isDealLoaded) {
      fetchTransporters();
    }
  }, [
    isVisible,
    dealId,
    fetchTransporters,
    isFreightForwarderRole,
    isDealLoaded,
  ]);

  return (
    <Modal
      title={t("bids.bid", "Bid")}
      onCancel={onHide}
      visible={isVisible}
      onOk={handleOnSave}
      type="primary"
      loading={isBtnLoading}
      hideButtons={transporters.length === 0 || mode === ModalMode.VIEW}
    >
      {isLoading ? (
        <Spin />
      ) : transporters.length > 0 ? (
        <Form form={form} layout="vertical">
          <Row gutter={[15, 15]}>
            <Form.Item
              name="dealId"
              hidden={true}
              initialValue={dealId}
            ></Form.Item>

            <Col xs={24} md={12}>
              <Form.Item
                name="transporterId"
                label={t("bid.transporter", "Transporter")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "bid.select-transporter",
                      "Please select a transporter"
                    ),
                  },
                ]}
              >
                <Select
                  labelInValue // Add this prop to ensure Select handles value and label
                  disabled={readOnlyMode}
                  placeholder="Select Transporter"
                  onChange={(option) => {
                    setTransporterId(option.value); // Set the ID from the selected option
                    form.setFieldsValue({
                      driverId: undefined,
                      equipmentId: undefined,
                    });
                  }}
                >
                  {transporters.map((transporter) => (
                    <Select.Option key={transporter.id} value={transporter.id}>
                      {transporter.businessName} ({transporter.businessNumber})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="driverId"
                label={t("bid.driver", "Drivers")}
                rules={[
                  {
                    required: false,
                    message: t("bid.select-driver", "Please select a Driver"),
                  },
                ]}
              >
                <Select placeholder="Select Driver" disabled={readOnlyMode}>
                  {drivers.map((driver: any) => (
                    <Select.Option key={driver.id}>
                      {driver.firstName} {driver.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="equipmentId"
                label={t("bid.equipment", "Equipment")}
                rules={[
                  {
                    required: false,
                    message: t(
                      "bid.select-equipment",
                      "Please select equipment"
                    ),
                  },
                ]}
              >
                <Select placeholder="Select equipment" disabled={readOnlyMode}>
                  {equipments.map((equipment: any) => (
                    <Select.Option value={equipment.id} key={equipment.id}>
                      {equipment.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="cost"
                label={t("bid.cost", "Cost")}
                rules={[
                  {
                    required: true,
                    message: t("bid.enter-cost", "Please enter cost"),
                  },
                ]}
              >
                <Input
                  disabled={readOnlyMode}
                  type="number"
                  placeholder="Enter cost"
                  addonAfter="€"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="parkingFee"
                label={t("bid.parking-fee", "Parking fee")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "bid.enter-parking-fee",
                      "Please enter parking fee"
                    ),
                  },
                ]}
              >
                <Input
                  type="number"
                  disabled={readOnlyMode}
                  placeholder="Enter parking fee"
                  addonAfter="€"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="price"
                label={t("bid.price", "Price")}
                rules={[
                  {
                    required: true,
                    message: t("bid.enter-price", "Please enter price"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const cost = Number(getFieldValue("cost") || 0);
                      const parkingFee = Number(
                        getFieldValue("parkingFee") || 0
                      );

                      if (
                        value !== undefined &&
                        Number(value) < cost + parkingFee
                      ) {
                        return Promise.reject(
                          new Error(
                            t(
                              "bid.price-smaller-warning",
                              "Price should be greater than or equal to the sum of cost and parking fee"
                            )
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  disabled={readOnlyMode}
                  type="number"
                  placeholder="Enter price"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item>
                {!readOnlyMode ? (
                  <Dragger
                    fileList={fileList}
                    beforeUpload={(file) => {
                      setFileList((prev: any): any => [...prev, file]);
                      return false;
                    }}
                    onRemove={(file) => {
                      if (updateMode) {
                        setExistingFiles((prev: any) =>
                          prev.map((x: any) => {
                            if (x.fileName.includes(file.name)) {
                              x.deleted = true;
                            }
                            return x;
                          })
                        );
                      }
                      const index = fileList.indexOf(file as never);
                      const newFileList = fileList.slice();
                      newFileList.splice(index, 1);
                      setFileList(newFileList);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file(s) to this area to upload
                    </p>
                  </Dragger>
                ) : (
                  fileList?.length !== 0 && (
                    <Button
                      type="white"
                      onClick={() => downloadFilesFromBase64(fileList)}
                    >
                      <FeatherIcon icon="download" size="24" />
                      Download files
                    </Button>
                  )
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <div>No available transporter</div>
      )}
    </Modal>
  );
};

export default Bid;
