/* tslint:disable */
/* eslint-disable */
/**
 * LogisticxApp PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const UserFilterType = {
  ACTIVE: 1,
  PENDING: 2,
  DISABLED: 3,
  DELETED: 4,
  ALL: 5,
  MINE: 6,
} as const;

export type UserFilterType =
  (typeof UserFilterType)[keyof typeof UserFilterType];
