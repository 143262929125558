import React from "react";
import PropTypes, { object } from "prop-types";
import { ModalStyled } from "./styled";
import { Button } from "../buttons/buttons";

const Modal = (props) => {
  const {
    onCancel,
    className,
    onOk,
    visible,
    title,
    type,
    disabled,
    color,
    footer,
    width,
    loading = false,
    children,
    hideButtons,
  } = props;

  return (
    <ModalStyled
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      footer={
        hideButtons
          ? null
          : footer || footer === null
          ? footer
          : [
              <div
                style={{ paddingTop: 15, paddingBottom: 15 }}
                key="modal-footer"
              >
                <Button
                  type="danger"
                  transparented
                  key="back"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type={type}
                  disabled={disabled}
                  loading={loading}
                  key="submit"
                  onClick={onOk}
                >
                  Save Change
                </Button>
              </div>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
};

Modal.defaultProps = {
  width: 620,
  className: "atbd-modal",
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  footer: PropTypes.arrayOf(object),
  width: PropTypes.number,
  loading: PropTypes.bool,
  hideButtons: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

const alertModal = ModalStyled;
export { Modal, alertModal };
