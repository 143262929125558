/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountProfileDto } from '../models';
// @ts-ignore
import { ExchangeTokenRequest } from '../models';
// @ts-ignore
import { ForgotPasswordCommand } from '../models';
// @ts-ignore
import { LoginCommand } from '../models';
// @ts-ignore
import { LoginResponse } from '../models';
// @ts-ignore
import { RefreshTokenRequest } from '../models';
// @ts-ignore
import { RegisterCommand } from '../models';
// @ts-ignore
import { ResetPasswordCommand } from '../models';
// @ts-ignore
import { UpdateUserProfileCommand } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ExchangeTokenRequest} [exchangeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthExchangeTokenPost: async (exchangeTokenRequest?: ExchangeTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/exchange-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordCommand} [forgotPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthForgotPasswordPost: async (forgotPasswordCommand?: ForgotPasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginCommand} [loginCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost: async (loginCommand?: LoginCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserProfileCommand} [updateUserProfileCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProfilePut: async (updateUserProfileCommand?: UpdateUserProfileCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProfileCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshTokenPost: async (refreshTokenRequest?: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterCommand} [registerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRegisterPost: async (registerCommand?: RegisterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordCommand} [resetPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetPasswordPost: async (resetPasswordCommand?: ResetPasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ExchangeTokenRequest} [exchangeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthExchangeTokenPost(exchangeTokenRequest?: ExchangeTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthExchangeTokenPost(exchangeTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordCommand} [forgotPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthForgotPasswordPost(forgotPasswordCommand?: ForgotPasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthForgotPasswordPost(forgotPasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginCommand} [loginCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthLoginPost(loginCommand?: LoginCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLoginPost(loginCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserProfileCommand} [updateUserProfileCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthProfilePut(updateUserProfileCommand?: UpdateUserProfileCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProfilePut(updateUserProfileCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthRefreshTokenPost(refreshTokenRequest?: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRefreshTokenPost(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterCommand} [registerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthRegisterPost(registerCommand?: RegisterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRegisterPost(registerCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordCommand} [resetPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthResetPasswordPost(resetPasswordCommand?: ResetPasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetPasswordPost(resetPasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {ExchangeTokenRequest} [exchangeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthExchangeTokenPost(exchangeTokenRequest?: ExchangeTokenRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiAuthExchangeTokenPost(exchangeTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordCommand} [forgotPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthForgotPasswordPost(forgotPasswordCommand?: ForgotPasswordCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthForgotPasswordPost(forgotPasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginCommand} [loginCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost(loginCommand?: LoginCommand, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiAuthLoginPost(loginCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProfileGet(options?: any): AxiosPromise<AccountProfileDto> {
            return localVarFp.apiAuthProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserProfileCommand} [updateUserProfileCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProfilePut(updateUserProfileCommand?: UpdateUserProfileCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthProfilePut(updateUserProfileCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshTokenPost(refreshTokenRequest?: RefreshTokenRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiAuthRefreshTokenPost(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterCommand} [registerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRegisterPost(registerCommand?: RegisterCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthRegisterPost(registerCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordCommand} [resetPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetPasswordPost(resetPasswordCommand?: ResetPasswordCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthResetPasswordPost(resetPasswordCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiAuthExchangeTokenPost operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthExchangeTokenPostRequest
 */
export interface AuthApiApiAuthExchangeTokenPostRequest {
    /**
     * 
     * @type {ExchangeTokenRequest}
     * @memberof AuthApiApiAuthExchangeTokenPost
     */
    readonly exchangeTokenRequest?: ExchangeTokenRequest
}

/**
 * Request parameters for apiAuthForgotPasswordPost operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthForgotPasswordPostRequest
 */
export interface AuthApiApiAuthForgotPasswordPostRequest {
    /**
     * 
     * @type {ForgotPasswordCommand}
     * @memberof AuthApiApiAuthForgotPasswordPost
     */
    readonly forgotPasswordCommand?: ForgotPasswordCommand
}

/**
 * Request parameters for apiAuthLoginPost operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthLoginPostRequest
 */
export interface AuthApiApiAuthLoginPostRequest {
    /**
     * 
     * @type {LoginCommand}
     * @memberof AuthApiApiAuthLoginPost
     */
    readonly loginCommand?: LoginCommand
}

/**
 * Request parameters for apiAuthProfilePut operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthProfilePutRequest
 */
export interface AuthApiApiAuthProfilePutRequest {
    /**
     * 
     * @type {UpdateUserProfileCommand}
     * @memberof AuthApiApiAuthProfilePut
     */
    readonly updateUserProfileCommand?: UpdateUserProfileCommand
}

/**
 * Request parameters for apiAuthRefreshTokenPost operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthRefreshTokenPostRequest
 */
export interface AuthApiApiAuthRefreshTokenPostRequest {
    /**
     * 
     * @type {RefreshTokenRequest}
     * @memberof AuthApiApiAuthRefreshTokenPost
     */
    readonly refreshTokenRequest?: RefreshTokenRequest
}

/**
 * Request parameters for apiAuthRegisterPost operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthRegisterPostRequest
 */
export interface AuthApiApiAuthRegisterPostRequest {
    /**
     * 
     * @type {RegisterCommand}
     * @memberof AuthApiApiAuthRegisterPost
     */
    readonly registerCommand?: RegisterCommand
}

/**
 * Request parameters for apiAuthResetPasswordPost operation in AuthApi.
 * @export
 * @interface AuthApiApiAuthResetPasswordPostRequest
 */
export interface AuthApiApiAuthResetPasswordPostRequest {
    /**
     * 
     * @type {ResetPasswordCommand}
     * @memberof AuthApiApiAuthResetPasswordPost
     */
    readonly resetPasswordCommand?: ResetPasswordCommand
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiApiAuthExchangeTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthExchangeTokenPost(requestParameters: AuthApiApiAuthExchangeTokenPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthExchangeTokenPost(requestParameters.exchangeTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiApiAuthForgotPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthForgotPasswordPost(requestParameters: AuthApiApiAuthForgotPasswordPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthForgotPasswordPost(requestParameters.forgotPasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiApiAuthLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthLoginPost(requestParameters: AuthApiApiAuthLoginPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthLoginPost(requestParameters.loginCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthLogoutPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthProfileGet(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthProfileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiApiAuthProfilePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthProfilePut(requestParameters: AuthApiApiAuthProfilePutRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthProfilePut(requestParameters.updateUserProfileCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiApiAuthRefreshTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthRefreshTokenPost(requestParameters: AuthApiApiAuthRefreshTokenPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthRefreshTokenPost(requestParameters.refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiApiAuthRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthRegisterPost(requestParameters: AuthApiApiAuthRegisterPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthRegisterPost(requestParameters.registerCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiApiAuthResetPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthResetPasswordPost(requestParameters: AuthApiApiAuthResetPasswordPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthResetPasswordPost(requestParameters.resetPasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
