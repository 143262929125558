/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCompanyRelationRequest } from '../models';
/**
 * CompanyRelationsApi - axios parameter creator
 * @export
 */
export const CompanyRelationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [primaryCompanyId] 
         * @param {string} [relatedCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRelationsCheckExistsGet: async (primaryCompanyId?: string, relatedCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company-relations/checkExists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (primaryCompanyId !== undefined) {
                localVarQueryParameter['PrimaryCompanyId'] = primaryCompanyId;
            }

            if (relatedCompanyId !== undefined) {
                localVarQueryParameter['RelatedCompanyId'] = relatedCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCompanyRelationRequest} [createCompanyRelationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRelationsPost: async (createCompanyRelationRequest?: CreateCompanyRelationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company-relations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRelationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relatedCompanyId2 
         * @param {string} [relatedCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRelationsRelatedCompanyIdDelete: async (relatedCompanyId2: string, relatedCompanyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relatedCompanyId2' is not null or undefined
            assertParamExists('apiCompanyRelationsRelatedCompanyIdDelete', 'relatedCompanyId2', relatedCompanyId2)
            const localVarPath = `/api/company-relations/{relatedCompanyId}`
                .replace(`{${"relatedCompanyId"}}`, encodeURIComponent(String(relatedCompanyId2)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (relatedCompanyId !== undefined) {
                localVarQueryParameter['RelatedCompanyId'] = relatedCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyRelationsApi - functional programming interface
 * @export
 */
export const CompanyRelationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyRelationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [primaryCompanyId] 
         * @param {string} [relatedCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyRelationsCheckExistsGet(primaryCompanyId?: string, relatedCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyRelationsCheckExistsGet(primaryCompanyId, relatedCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCompanyRelationRequest} [createCompanyRelationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyRelationsPost(createCompanyRelationRequest?: CreateCompanyRelationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyRelationsPost(createCompanyRelationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relatedCompanyId2 
         * @param {string} [relatedCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyRelationsRelatedCompanyIdDelete(relatedCompanyId2: string, relatedCompanyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyRelationsRelatedCompanyIdDelete(relatedCompanyId2, relatedCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyRelationsApi - factory interface
 * @export
 */
export const CompanyRelationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyRelationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [primaryCompanyId] 
         * @param {string} [relatedCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRelationsCheckExistsGet(primaryCompanyId?: string, relatedCompanyId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiCompanyRelationsCheckExistsGet(primaryCompanyId, relatedCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCompanyRelationRequest} [createCompanyRelationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRelationsPost(createCompanyRelationRequest?: CreateCompanyRelationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyRelationsPost(createCompanyRelationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relatedCompanyId2 
         * @param {string} [relatedCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRelationsRelatedCompanyIdDelete(relatedCompanyId2: string, relatedCompanyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyRelationsRelatedCompanyIdDelete(relatedCompanyId2, relatedCompanyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCompanyRelationsCheckExistsGet operation in CompanyRelationsApi.
 * @export
 * @interface CompanyRelationsApiApiCompanyRelationsCheckExistsGetRequest
 */
export interface CompanyRelationsApiApiCompanyRelationsCheckExistsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyRelationsApiApiCompanyRelationsCheckExistsGet
     */
    readonly primaryCompanyId?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyRelationsApiApiCompanyRelationsCheckExistsGet
     */
    readonly relatedCompanyId?: string
}

/**
 * Request parameters for apiCompanyRelationsPost operation in CompanyRelationsApi.
 * @export
 * @interface CompanyRelationsApiApiCompanyRelationsPostRequest
 */
export interface CompanyRelationsApiApiCompanyRelationsPostRequest {
    /**
     * 
     * @type {CreateCompanyRelationRequest}
     * @memberof CompanyRelationsApiApiCompanyRelationsPost
     */
    readonly createCompanyRelationRequest?: CreateCompanyRelationRequest
}

/**
 * Request parameters for apiCompanyRelationsRelatedCompanyIdDelete operation in CompanyRelationsApi.
 * @export
 * @interface CompanyRelationsApiApiCompanyRelationsRelatedCompanyIdDeleteRequest
 */
export interface CompanyRelationsApiApiCompanyRelationsRelatedCompanyIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyRelationsApiApiCompanyRelationsRelatedCompanyIdDelete
     */
    readonly relatedCompanyId2: string

    /**
     * 
     * @type {string}
     * @memberof CompanyRelationsApiApiCompanyRelationsRelatedCompanyIdDelete
     */
    readonly relatedCompanyId?: string
}

/**
 * CompanyRelationsApi - object-oriented interface
 * @export
 * @class CompanyRelationsApi
 * @extends {BaseAPI}
 */
export class CompanyRelationsApi extends BaseAPI {
    /**
     * 
     * @param {CompanyRelationsApiApiCompanyRelationsCheckExistsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRelationsApi
     */
    public apiCompanyRelationsCheckExistsGet(requestParameters: CompanyRelationsApiApiCompanyRelationsCheckExistsGetRequest = {}, options?: AxiosRequestConfig) {
        return CompanyRelationsApiFp(this.configuration).apiCompanyRelationsCheckExistsGet(requestParameters.primaryCompanyId, requestParameters.relatedCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyRelationsApiApiCompanyRelationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRelationsApi
     */
    public apiCompanyRelationsPost(requestParameters: CompanyRelationsApiApiCompanyRelationsPostRequest = {}, options?: AxiosRequestConfig) {
        return CompanyRelationsApiFp(this.configuration).apiCompanyRelationsPost(requestParameters.createCompanyRelationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyRelationsApiApiCompanyRelationsRelatedCompanyIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRelationsApi
     */
    public apiCompanyRelationsRelatedCompanyIdDelete(requestParameters: CompanyRelationsApiApiCompanyRelationsRelatedCompanyIdDeleteRequest, options?: AxiosRequestConfig) {
        return CompanyRelationsApiFp(this.configuration).apiCompanyRelationsRelatedCompanyIdDelete(requestParameters.relatedCompanyId2, requestParameters.relatedCompanyId, options).then((request) => request(this.axios, this.basePath));
    }
}
