import { useState, useEffect } from "react";
import { CompaniesApi } from "api/api";
import { CompanyStatus } from "../../../api";

const companiesApi = new CompaniesApi();

export const useCompaniesFetch = ({
  searchCompanies,
  filterType,
  companyType,
}: any) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: searchCompanies,
    sortBy: "",
    sortType: "",
    status: filterType || CompanyStatus.NUMBER_1,
    type: companyType,
  });

  const fetchCompanies = async () => {
    setIsLoading(true);
    try {
      const response = (
        await new CompaniesApi().apiCompaniesByTypeGet({
          ...query,
          search: searchCompanies,
          status: filterType || CompanyStatus.NUMBER_1,
          type: companyType,
        })
      ).data;
      setData(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [query, companyType, filterType, searchCompanies]);

  return { data, isLoading, fetchCompanies, query, setQuery };
};
