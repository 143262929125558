import "antd/dist/antd.less";
import { AppRouter } from "./router/AppRouter";
import "./static/css/style.css";
import "./styles/custom.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ConfigProvider } from "antd";
import "antd/dist/antd.less";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import config from "./config/config";
import store from "./redux/store";
import "./static/css/style.css";
import './i18n'
import locale from 'antd/lib/locale/en_US';
import { Suspense } from "react";

const { theme, topMenu, darkMode, rtl } = config;
const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ConfigProvider direction={"ltr"} locale={locale}>
        <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
          <Provider store={store}>
              <AppRouter />
          </Provider>
        </ThemeProvider>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
