import { Table } from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";

import { Cards } from "components/cards/frame/cards-frame";
import { Button } from "components/buttons/buttons";

import { EquipmentApi } from "api/api";

import { TableWrapper } from "container/styled";
import { useEquipmentsFetch } from "./hooks/useEquipmentsFetch";

import { hasPermission } from "utility/accessibility/hasPermission";
import openNotificationWithIcon from "utility/notification";
import Equipment from "components/equipments/equipment";
import {
  EquipmentProps,
  useEquipmentColumns,
} from "components/equipments/equipmentDataTableColumns";
import { useModalState } from "hooks/useModalState";

const equipmentsApi = new EquipmentApi();

const Equipments = ({ companyId }: EquipmentProps) => {
  const { t } = useTranslation();
  const { data, isLoading, fetchEquipments, setQuery } = useEquipmentsFetch({
    companyId,
  });

  const {
    modalState,
    closeModal,
    handleEditClick,
    handleViewClick,
    handleAddClick,
  } = useModalState(fetchEquipments);

  const canAddEquipments = hasPermission("equipments:add");

  const columns = useEquipmentColumns({
    handleViewClick,
    handleEditClick,
    handleDeleteClick: async (item: any) => {
      try {
        await equipmentsApi.apiEquipmentsIdDelete({ id: item.id });
        openNotificationWithIcon(
          "success",
          t(
            "equipmentsModal:equipments-deleted-success",
            "Equipments deleted successfully!"
          )
        );
        fetchEquipments();
      } catch (error) {}
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Cards
        title={t("equipments:title", "Equipments")}
        size="large"
        isbutton={
          canAddEquipments && (
            <Button
              onClick={handleAddClick}
              className="btn-add_new"
              size="default"
              type="white"
              key="add-codebook"
            >
              <FeatherIcon icon="plus" size={25} />
              {t("equipments:button.add", "Add New")}
            </Button>
          )
        }
      >
        <TableWrapper className="table-responsive">
          <Table
            loading={isLoading}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            pagination={{
              current: data?.pageIndex,
              total: data?.totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "1000"],
              onChange: (pageNumber, pageSize) =>
                setQuery((prev) => ({ ...prev, pageNumber, pageSize })),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </Cards>
      <Equipment
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        onSave={fetchEquipments}
        companyId={companyId}
        mode={modalState.mode as any}
        equipmentToEdit={modalState.itemForEditModal}
      />
    </div>
  );
};

export default Equipments;
