/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddLanguageCommand } from '../models';
// @ts-ignore
import { I18nLanguageCodeDto } from '../models';
// @ts-ignore
import { LanguageDto } from '../models';
// @ts-ignore
import { LanguageForUiDto } from '../models';
// @ts-ignore
import { UpdateLanguageCommand } from '../models';
/**
 * LanguagesApi - axios parameter creator
 * @export
 */
export const LanguagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesForUiGet: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Languages/for-ui`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGet: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesI18nCodesGet: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Languages/i18n-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLanguagesIdDelete', 'id', id)
            const localVarPath = `/api/Languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLanguageCommand} [updateLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesIdPut: async (id: string, updateLanguageCommand?: UpdateLanguageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLanguagesIdPut', 'id', id)
            const localVarPath = `/api/Languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLanguageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddLanguageCommand} [addLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesPost: async (addLanguageCommand?: AddLanguageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLanguageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguagesApi - functional programming interface
 * @export
 */
export const LanguagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesForUiGet(query?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageForUiDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesForUiGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesGet(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesGet(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesI18nCodesGet(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<I18nLanguageCodeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesI18nCodesGet(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLanguageCommand} [updateLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesIdPut(id: string, updateLanguageCommand?: UpdateLanguageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesIdPut(id, updateLanguageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddLanguageCommand} [addLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesPost(addLanguageCommand?: AddLanguageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesPost(addLanguageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguagesApi - factory interface
 * @export
 */
export const LanguagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguagesApiFp(configuration)
    return {
        /**
         * 
         * @param {object} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesForUiGet(query?: object, options?: any): AxiosPromise<Array<LanguageForUiDto>> {
            return localVarFp.apiLanguagesForUiGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGet(search?: string, options?: any): AxiosPromise<Array<LanguageDto>> {
            return localVarFp.apiLanguagesGet(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesI18nCodesGet(search?: string, options?: any): AxiosPromise<Array<I18nLanguageCodeDto>> {
            return localVarFp.apiLanguagesI18nCodesGet(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLanguagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLanguageCommand} [updateLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesIdPut(id: string, updateLanguageCommand?: UpdateLanguageCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiLanguagesIdPut(id, updateLanguageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddLanguageCommand} [addLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesPost(addLanguageCommand?: AddLanguageCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiLanguagesPost(addLanguageCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiLanguagesForUiGet operation in LanguagesApi.
 * @export
 * @interface LanguagesApiApiLanguagesForUiGetRequest
 */
export interface LanguagesApiApiLanguagesForUiGetRequest {
    /**
     * 
     * @type {object}
     * @memberof LanguagesApiApiLanguagesForUiGet
     */
    readonly query?: object
}

/**
 * Request parameters for apiLanguagesGet operation in LanguagesApi.
 * @export
 * @interface LanguagesApiApiLanguagesGetRequest
 */
export interface LanguagesApiApiLanguagesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LanguagesApiApiLanguagesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiLanguagesI18nCodesGet operation in LanguagesApi.
 * @export
 * @interface LanguagesApiApiLanguagesI18nCodesGetRequest
 */
export interface LanguagesApiApiLanguagesI18nCodesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LanguagesApiApiLanguagesI18nCodesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiLanguagesIdDelete operation in LanguagesApi.
 * @export
 * @interface LanguagesApiApiLanguagesIdDeleteRequest
 */
export interface LanguagesApiApiLanguagesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LanguagesApiApiLanguagesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiLanguagesIdPut operation in LanguagesApi.
 * @export
 * @interface LanguagesApiApiLanguagesIdPutRequest
 */
export interface LanguagesApiApiLanguagesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof LanguagesApiApiLanguagesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateLanguageCommand}
     * @memberof LanguagesApiApiLanguagesIdPut
     */
    readonly updateLanguageCommand?: UpdateLanguageCommand
}

/**
 * Request parameters for apiLanguagesPost operation in LanguagesApi.
 * @export
 * @interface LanguagesApiApiLanguagesPostRequest
 */
export interface LanguagesApiApiLanguagesPostRequest {
    /**
     * 
     * @type {AddLanguageCommand}
     * @memberof LanguagesApiApiLanguagesPost
     */
    readonly addLanguageCommand?: AddLanguageCommand
}

/**
 * LanguagesApi - object-oriented interface
 * @export
 * @class LanguagesApi
 * @extends {BaseAPI}
 */
export class LanguagesApi extends BaseAPI {
    /**
     * 
     * @param {LanguagesApiApiLanguagesForUiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesForUiGet(requestParameters: LanguagesApiApiLanguagesForUiGetRequest = {}, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesForUiGet(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguagesApiApiLanguagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesGet(requestParameters: LanguagesApiApiLanguagesGetRequest = {}, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguagesApiApiLanguagesI18nCodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesI18nCodesGet(requestParameters: LanguagesApiApiLanguagesI18nCodesGetRequest = {}, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesI18nCodesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguagesApiApiLanguagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesIdDelete(requestParameters: LanguagesApiApiLanguagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguagesApiApiLanguagesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesIdPut(requestParameters: LanguagesApiApiLanguagesIdPutRequest, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesIdPut(requestParameters.id, requestParameters.updateLanguageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguagesApiApiLanguagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesPost(requestParameters: LanguagesApiApiLanguagesPostRequest = {}, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesPost(requestParameters.addLanguageCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
