import { useState, useEffect } from "react";
import { BidsApi, DriversApi } from "api/api";

const bidsApi = new BidsApi();

export const useBidsFetch = ({ dealId }: any) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
    dealId: dealId,
  });

  const fetchBids = async () => {
    setIsLoading(true);
    try {
      const { data } = await bidsApi.apiBidsGet(query);
      setData(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBids();
  }, [query]);

  return { data, isLoading, fetchBids, query, setQuery, setData };
};
