import { CodebookApi } from "api/api";
import { useEffect, useState } from "react";

const codebookApi = new CodebookApi();
const HS_CODE = "HS_CODE";

export const useFetchHSCodes = () => {
  const [hsCodes, setHsCodes] = useState<any>();

  useEffect(() => {
    const fetchHSCodes = async () => {
      try {
        const response = (
          await codebookApi.apiCodebookByParentCodeGet({
            code: HS_CODE,
            pageSize: 1000,
          })
        ).data;

        setHsCodes(response as any);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHSCodes();
  }, []);
  return hsCodes;
};
