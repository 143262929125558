import { Col, Radio, Row, Spin, message } from "antd";
import { useState } from "react";
import { Main, TopToolBox } from "container/styled";
import { useTranslation } from "react-i18next";

import { DealStatusEnum } from "api/models";

import styled from "styled-components";
import { Cards } from "components/cards/frame/cards-frame";
import WorkbookTable from "./WorkbookTable";

const ConfirmedBadge = styled.span`
  padding: 3px 6px;
  border-radius: 10px;
  background: #c0dd77;
  color: #7aa809;
`;

function Workbook() {
  const { t } = useTranslation();
  const filterKeys = [
    {
      id: DealStatusEnum.NUMBER_2,
      name: t("users:select.confirmed", "Confirmed"),
    },
    {
      id: DealStatusEnum.NUMBER_1,
      name: t("users:select.quoting", "Quoting"),
    },

    { id: DealStatusEnum.NUMBER_3, name: t("users:select.all", "All") },
  ];

  const [filterType, setFilterType] = useState<DealStatusEnum>();

  const onFilterChange = (e: any) => {
    setFilterType(e.target.value);
  };

  return (
    <>
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15}>
                  <Col lg={12} md={14} xs={24}>
                    <div
                      className="table-toolbox-menu"
                      style={{ float: "left", marginLeft: 20 }}
                    >
                      <span className="toolbox-menu-title">
                        {t("users:lable.status", "Status:")}
                      </span>
                      <Radio.Group
                        name="filterKey"
                        onChange={onFilterChange}
                        defaultValue={DealStatusEnum.NUMBER_2}
                      >
                        {[...new Set(filterKeys)].map((item, i) => {
                          return (
                            <Radio.Button
                              key={item.id + "_" + i}
                              value={item.id}
                            >
                              {item.name}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row>

          <WorkbookTable filterType={filterType} />
        </Cards>
      </Main>
    </>
  );
}

export default Workbook;
