/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeCompanyStatusDto } from '../models';
// @ts-ignore
import { CompanyDetailsDto } from '../models';
// @ts-ignore
import { CompanyDto } from '../models';
// @ts-ignore
import { CompanyDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { CompanyNonWorkingDaysDto } from '../models';
// @ts-ignore
import { CompanyNonWorkingDaysDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { CompanyStatus } from '../models';
// @ts-ignore
import { CompanyTypeEnum } from '../models';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesBusinessIdSearchGet: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('apiCompaniesBusinessIdSearchGet', 'businessId', businessId)
            const localVarPath = `/api/companies/{businessId}/search`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {CompanyStatus} [status] 
         * @param {CompanyTypeEnum} [type] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesByTypeGet: async (pageNumber?: number, pageSize?: number, status?: CompanyStatus, type?: CompanyTypeEnum, sortBy?: string, sortType?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies/byType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {CompanyStatus} [status] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesGet: async (pageNumber?: number, pageSize?: number, status?: CompanyStatus, sortBy?: string, sortType?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangeCompanyStatusDto} [changeCompanyStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdChangeStatusPatch: async (id: string, changeCompanyStatusDto?: ChangeCompanyStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdChangeStatusPatch', 'id', id)
            const localVarPath = `/api/companies/{id}/change-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCompanyStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdDelete', 'id', id)
            const localVarPath = `/api/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdGet', 'id', id)
            const localVarPath = `/api/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdNonWorkingDaysDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdNonWorkingDaysDelete', 'id', id)
            const localVarPath = `/api/companies/{id}/non-working-days`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdNonWorkingDaysGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdNonWorkingDaysGet', 'id', id)
            const localVarPath = `/api/companies/{id}/non-working-days`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [description] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdNonWorkingDaysPut: async (id: string, id2?: string, startDate?: string, endDate?: string, description?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdNonWorkingDaysPut', 'id', id)
            const localVarPath = `/api/companies/{id}/non-working-days`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (startDate !== undefined) { 
                localVarFormParams.append('StartDate', startDate as any);
            }
    
            if (endDate !== undefined) { 
                localVarFormParams.append('EndDate', endDate as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (companyId !== undefined) { 
                localVarFormParams.append('CompanyId', companyId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [businessName] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [tradeName] 
         * @param {string} [businessNumber] 
         * @param {string} [fiscalNumber] 
         * @param {string} [address] 
         * @param {string} [comments] 
         * @param {string} [phoneNumber] 
         * @param {string} [contactPersonId] 
         * @param {string} [companyEmail] 
         * @param {string} [createdAt] 
         * @param {string} [updatedAt] 
         * @param {CompanyTypeEnum} [type] 
         * @param {boolean} [isDeleted] 
         * @param {CompanyStatus} [status] 
         * @param {string} [personalNumber] 
         * @param {string} [placeOfBirth] 
         * @param {string} [defaultLanguage] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [companyFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdPut: async (id: string, id2?: string, businessName?: string, firstName?: string, lastName?: string, tradeName?: string, businessNumber?: string, fiscalNumber?: string, address?: string, comments?: string, phoneNumber?: string, contactPersonId?: string, companyEmail?: string, createdAt?: string, updatedAt?: string, type?: CompanyTypeEnum, isDeleted?: boolean, status?: CompanyStatus, personalNumber?: string, placeOfBirth?: string, defaultLanguage?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, companyFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesIdPut', 'id', id)
            const localVarPath = `/api/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (businessName !== undefined) { 
                localVarFormParams.append('BusinessName', businessName as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (tradeName !== undefined) { 
                localVarFormParams.append('TradeName', tradeName as any);
            }
    
            if (businessNumber !== undefined) { 
                localVarFormParams.append('BusinessNumber', businessNumber as any);
            }
    
            if (fiscalNumber !== undefined) { 
                localVarFormParams.append('FiscalNumber', fiscalNumber as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
            if (contactPersonId !== undefined) { 
                localVarFormParams.append('ContactPersonId', contactPersonId as any);
            }
    
            if (companyEmail !== undefined) { 
                localVarFormParams.append('CompanyEmail', companyEmail as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (isDeleted !== undefined) { 
                localVarFormParams.append('IsDeleted', isDeleted as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('Status', status as any);
            }
    
            if (personalNumber !== undefined) { 
                localVarFormParams.append('PersonalNumber', personalNumber as any);
            }
    
            if (placeOfBirth !== undefined) { 
                localVarFormParams.append('PlaceOfBirth', placeOfBirth as any);
            }
    
            if (defaultLanguage !== undefined) { 
                localVarFormParams.append('DefaultLanguage', defaultLanguage as any);
            }
                if (existingFileNames) {
                existingFileNames.forEach((element) => {
                    localVarFormParams.append('ExistingFileNames', element as any);
                })
            }

                if (toBeDeletedFileNames) {
                toBeDeletedFileNames.forEach((element) => {
                    localVarFormParams.append('ToBeDeletedFileNames', element as any);
                })
            }

                if (companyFiles) {
                companyFiles.forEach((element) => {
                    localVarFormParams.append('CompanyFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [description] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesNonWorkingDayPost: async (startDate?: string, endDate?: string, description?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies/non-working-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (startDate !== undefined) { 
                localVarFormParams.append('StartDate', startDate as any);
            }
    
            if (endDate !== undefined) { 
                localVarFormParams.append('EndDate', endDate as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (companyId !== undefined) { 
                localVarFormParams.append('CompanyId', companyId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesNonWorkingDaysGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies/non-working-days`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [businessName] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [tradeName] 
         * @param {string} [businessNumber] 
         * @param {string} [fiscalNumber] 
         * @param {string} [address] 
         * @param {string} [phoneNumber] 
         * @param {string} [contactPersonId] 
         * @param {string} [userEmail] 
         * @param {string} [companyEmail] 
         * @param {string} [createdAt] 
         * @param {string} [updatedAt] 
         * @param {CompanyTypeEnum} [type] 
         * @param {boolean} [isDeleted] 
         * @param {CompanyStatus} [status] 
         * @param {string} [personalNumber] 
         * @param {string} [placeOfBirth] 
         * @param {string} [defaultLanguage] 
         * @param {string} [comments] 
         * @param {Array<File>} [companyFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesPost: async (businessName?: string, firstName?: string, lastName?: string, tradeName?: string, businessNumber?: string, fiscalNumber?: string, address?: string, phoneNumber?: string, contactPersonId?: string, userEmail?: string, companyEmail?: string, createdAt?: string, updatedAt?: string, type?: CompanyTypeEnum, isDeleted?: boolean, status?: CompanyStatus, personalNumber?: string, placeOfBirth?: string, defaultLanguage?: string, comments?: string, companyFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (businessName !== undefined) { 
                localVarFormParams.append('BusinessName', businessName as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (tradeName !== undefined) { 
                localVarFormParams.append('TradeName', tradeName as any);
            }
    
            if (businessNumber !== undefined) { 
                localVarFormParams.append('BusinessNumber', businessNumber as any);
            }
    
            if (fiscalNumber !== undefined) { 
                localVarFormParams.append('FiscalNumber', fiscalNumber as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
            if (contactPersonId !== undefined) { 
                localVarFormParams.append('ContactPersonId', contactPersonId as any);
            }
    
            if (userEmail !== undefined) { 
                localVarFormParams.append('UserEmail', userEmail as any);
            }
    
            if (companyEmail !== undefined) { 
                localVarFormParams.append('CompanyEmail', companyEmail as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (isDeleted !== undefined) { 
                localVarFormParams.append('IsDeleted', isDeleted as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('Status', status as any);
            }
    
            if (personalNumber !== undefined) { 
                localVarFormParams.append('PersonalNumber', personalNumber as any);
            }
    
            if (placeOfBirth !== undefined) { 
                localVarFormParams.append('PlaceOfBirth', placeOfBirth as any);
            }
    
            if (defaultLanguage !== undefined) { 
                localVarFormParams.append('DefaultLanguage', defaultLanguage as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
                if (companyFiles) {
                companyFiles.forEach((element) => {
                    localVarFormParams.append('CompanyFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesBusinessIdSearchGet(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesBusinessIdSearchGet(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {CompanyStatus} [status] 
         * @param {CompanyTypeEnum} [type] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesByTypeGet(pageNumber?: number, pageSize?: number, status?: CompanyStatus, type?: CompanyTypeEnum, sortBy?: string, sortType?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesByTypeGet(pageNumber, pageSize, status, type, sortBy, sortType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {CompanyStatus} [status] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesGet(pageNumber?: number, pageSize?: number, status?: CompanyStatus, sortBy?: string, sortType?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesGet(pageNumber, pageSize, status, sortBy, sortType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangeCompanyStatusDto} [changeCompanyStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdChangeStatusPatch(id: string, changeCompanyStatusDto?: ChangeCompanyStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdChangeStatusPatch(id, changeCompanyStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdNonWorkingDaysDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdNonWorkingDaysDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdNonWorkingDaysGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyNonWorkingDaysDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdNonWorkingDaysGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [description] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdNonWorkingDaysPut(id: string, id2?: string, startDate?: string, endDate?: string, description?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdNonWorkingDaysPut(id, id2, startDate, endDate, description, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [businessName] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [tradeName] 
         * @param {string} [businessNumber] 
         * @param {string} [fiscalNumber] 
         * @param {string} [address] 
         * @param {string} [comments] 
         * @param {string} [phoneNumber] 
         * @param {string} [contactPersonId] 
         * @param {string} [companyEmail] 
         * @param {string} [createdAt] 
         * @param {string} [updatedAt] 
         * @param {CompanyTypeEnum} [type] 
         * @param {boolean} [isDeleted] 
         * @param {CompanyStatus} [status] 
         * @param {string} [personalNumber] 
         * @param {string} [placeOfBirth] 
         * @param {string} [defaultLanguage] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [companyFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesIdPut(id: string, id2?: string, businessName?: string, firstName?: string, lastName?: string, tradeName?: string, businessNumber?: string, fiscalNumber?: string, address?: string, comments?: string, phoneNumber?: string, contactPersonId?: string, companyEmail?: string, createdAt?: string, updatedAt?: string, type?: CompanyTypeEnum, isDeleted?: boolean, status?: CompanyStatus, personalNumber?: string, placeOfBirth?: string, defaultLanguage?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, companyFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesIdPut(id, id2, businessName, firstName, lastName, tradeName, businessNumber, fiscalNumber, address, comments, phoneNumber, contactPersonId, companyEmail, createdAt, updatedAt, type, isDeleted, status, personalNumber, placeOfBirth, defaultLanguage, existingFileNames, toBeDeletedFileNames, companyFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [description] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesNonWorkingDayPost(startDate?: string, endDate?: string, description?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesNonWorkingDayPost(startDate, endDate, description, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesNonWorkingDaysGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyNonWorkingDaysDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesNonWorkingDaysGet(pageNumber, pageSize, sortBy, sortType, search, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [businessName] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [tradeName] 
         * @param {string} [businessNumber] 
         * @param {string} [fiscalNumber] 
         * @param {string} [address] 
         * @param {string} [phoneNumber] 
         * @param {string} [contactPersonId] 
         * @param {string} [userEmail] 
         * @param {string} [companyEmail] 
         * @param {string} [createdAt] 
         * @param {string} [updatedAt] 
         * @param {CompanyTypeEnum} [type] 
         * @param {boolean} [isDeleted] 
         * @param {CompanyStatus} [status] 
         * @param {string} [personalNumber] 
         * @param {string} [placeOfBirth] 
         * @param {string} [defaultLanguage] 
         * @param {string} [comments] 
         * @param {Array<File>} [companyFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesPost(businessName?: string, firstName?: string, lastName?: string, tradeName?: string, businessNumber?: string, fiscalNumber?: string, address?: string, phoneNumber?: string, contactPersonId?: string, userEmail?: string, companyEmail?: string, createdAt?: string, updatedAt?: string, type?: CompanyTypeEnum, isDeleted?: boolean, status?: CompanyStatus, personalNumber?: string, placeOfBirth?: string, defaultLanguage?: string, comments?: string, companyFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesPost(businessName, firstName, lastName, tradeName, businessNumber, fiscalNumber, address, phoneNumber, contactPersonId, userEmail, companyEmail, createdAt, updatedAt, type, isDeleted, status, personalNumber, placeOfBirth, defaultLanguage, comments, companyFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesBusinessIdSearchGet(businessId: string, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.apiCompaniesBusinessIdSearchGet(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {CompanyStatus} [status] 
         * @param {CompanyTypeEnum} [type] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesByTypeGet(pageNumber?: number, pageSize?: number, status?: CompanyStatus, type?: CompanyTypeEnum, sortBy?: string, sortType?: string, search?: string, options?: any): AxiosPromise<CompanyDtoOrdinalPaginatedList> {
            return localVarFp.apiCompaniesByTypeGet(pageNumber, pageSize, status, type, sortBy, sortType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {CompanyStatus} [status] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesGet(pageNumber?: number, pageSize?: number, status?: CompanyStatus, sortBy?: string, sortType?: string, search?: string, options?: any): AxiosPromise<CompanyDtoOrdinalPaginatedList> {
            return localVarFp.apiCompaniesGet(pageNumber, pageSize, status, sortBy, sortType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangeCompanyStatusDto} [changeCompanyStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdChangeStatusPatch(id: string, changeCompanyStatusDto?: ChangeCompanyStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesIdChangeStatusPatch(id, changeCompanyStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdGet(id: string, options?: any): AxiosPromise<CompanyDetailsDto> {
            return localVarFp.apiCompaniesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdNonWorkingDaysDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesIdNonWorkingDaysDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdNonWorkingDaysGet(id: string, options?: any): AxiosPromise<CompanyNonWorkingDaysDto> {
            return localVarFp.apiCompaniesIdNonWorkingDaysGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [description] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdNonWorkingDaysPut(id: string, id2?: string, startDate?: string, endDate?: string, description?: string, companyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesIdNonWorkingDaysPut(id, id2, startDate, endDate, description, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {string} [businessName] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [tradeName] 
         * @param {string} [businessNumber] 
         * @param {string} [fiscalNumber] 
         * @param {string} [address] 
         * @param {string} [comments] 
         * @param {string} [phoneNumber] 
         * @param {string} [contactPersonId] 
         * @param {string} [companyEmail] 
         * @param {string} [createdAt] 
         * @param {string} [updatedAt] 
         * @param {CompanyTypeEnum} [type] 
         * @param {boolean} [isDeleted] 
         * @param {CompanyStatus} [status] 
         * @param {string} [personalNumber] 
         * @param {string} [placeOfBirth] 
         * @param {string} [defaultLanguage] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [companyFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesIdPut(id: string, id2?: string, businessName?: string, firstName?: string, lastName?: string, tradeName?: string, businessNumber?: string, fiscalNumber?: string, address?: string, comments?: string, phoneNumber?: string, contactPersonId?: string, companyEmail?: string, createdAt?: string, updatedAt?: string, type?: CompanyTypeEnum, isDeleted?: boolean, status?: CompanyStatus, personalNumber?: string, placeOfBirth?: string, defaultLanguage?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, companyFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesIdPut(id, id2, businessName, firstName, lastName, tradeName, businessNumber, fiscalNumber, address, comments, phoneNumber, contactPersonId, companyEmail, createdAt, updatedAt, type, isDeleted, status, personalNumber, placeOfBirth, defaultLanguage, existingFileNames, toBeDeletedFileNames, companyFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [description] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesNonWorkingDayPost(startDate?: string, endDate?: string, description?: string, companyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesNonWorkingDayPost(startDate, endDate, description, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesNonWorkingDaysGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId?: string, options?: any): AxiosPromise<CompanyNonWorkingDaysDtoOrdinalPaginatedList> {
            return localVarFp.apiCompaniesNonWorkingDaysGet(pageNumber, pageSize, sortBy, sortType, search, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [businessName] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [tradeName] 
         * @param {string} [businessNumber] 
         * @param {string} [fiscalNumber] 
         * @param {string} [address] 
         * @param {string} [phoneNumber] 
         * @param {string} [contactPersonId] 
         * @param {string} [userEmail] 
         * @param {string} [companyEmail] 
         * @param {string} [createdAt] 
         * @param {string} [updatedAt] 
         * @param {CompanyTypeEnum} [type] 
         * @param {boolean} [isDeleted] 
         * @param {CompanyStatus} [status] 
         * @param {string} [personalNumber] 
         * @param {string} [placeOfBirth] 
         * @param {string} [defaultLanguage] 
         * @param {string} [comments] 
         * @param {Array<File>} [companyFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesPost(businessName?: string, firstName?: string, lastName?: string, tradeName?: string, businessNumber?: string, fiscalNumber?: string, address?: string, phoneNumber?: string, contactPersonId?: string, userEmail?: string, companyEmail?: string, createdAt?: string, updatedAt?: string, type?: CompanyTypeEnum, isDeleted?: boolean, status?: CompanyStatus, personalNumber?: string, placeOfBirth?: string, defaultLanguage?: string, comments?: string, companyFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesPost(businessName, firstName, lastName, tradeName, businessNumber, fiscalNumber, address, phoneNumber, contactPersonId, userEmail, companyEmail, createdAt, updatedAt, type, isDeleted, status, personalNumber, placeOfBirth, defaultLanguage, comments, companyFiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCompaniesBusinessIdSearchGet operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesBusinessIdSearchGetRequest
 */
export interface CompaniesApiApiCompaniesBusinessIdSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesBusinessIdSearchGet
     */
    readonly businessId: string
}

/**
 * Request parameters for apiCompaniesByTypeGet operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesByTypeGetRequest
 */
export interface CompaniesApiApiCompaniesByTypeGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {CompanyStatus}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly status?: CompanyStatus

    /**
     * 
     * @type {CompanyTypeEnum}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly type?: CompanyTypeEnum

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesByTypeGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiCompaniesGet operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesGetRequest
 */
export interface CompaniesApiApiCompaniesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiApiCompaniesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiApiCompaniesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {CompanyStatus}
     * @memberof CompaniesApiApiCompaniesGet
     */
    readonly status?: CompanyStatus

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiCompaniesIdChangeStatusPatch operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdChangeStatusPatchRequest
 */
export interface CompaniesApiApiCompaniesIdChangeStatusPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdChangeStatusPatch
     */
    readonly id: string

    /**
     * 
     * @type {ChangeCompanyStatusDto}
     * @memberof CompaniesApiApiCompaniesIdChangeStatusPatch
     */
    readonly changeCompanyStatusDto?: ChangeCompanyStatusDto
}

/**
 * Request parameters for apiCompaniesIdDelete operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdDeleteRequest
 */
export interface CompaniesApiApiCompaniesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCompaniesIdGet operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdGetRequest
 */
export interface CompaniesApiApiCompaniesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCompaniesIdNonWorkingDaysDelete operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdNonWorkingDaysDeleteRequest
 */
export interface CompaniesApiApiCompaniesIdNonWorkingDaysDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCompaniesIdNonWorkingDaysGet operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdNonWorkingDaysGetRequest
 */
export interface CompaniesApiApiCompaniesIdNonWorkingDaysGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCompaniesIdNonWorkingDaysPut operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdNonWorkingDaysPutRequest
 */
export interface CompaniesApiApiCompaniesIdNonWorkingDaysPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysPut
     */
    readonly id2?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysPut
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysPut
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysPut
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdNonWorkingDaysPut
     */
    readonly companyId?: string
}

/**
 * Request parameters for apiCompaniesIdPut operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesIdPutRequest
 */
export interface CompaniesApiApiCompaniesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly id2?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly businessName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly tradeName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly businessNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly fiscalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly comments?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly contactPersonId?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly companyEmail?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly createdAt?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly updatedAt?: string

    /**
     * 
     * @type {CompanyTypeEnum}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly type?: CompanyTypeEnum

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {CompanyStatus}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly status?: CompanyStatus

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly personalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly placeOfBirth?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly defaultLanguage?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly existingFileNames?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly toBeDeletedFileNames?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof CompaniesApiApiCompaniesIdPut
     */
    readonly companyFiles?: Array<File>
}

/**
 * Request parameters for apiCompaniesNonWorkingDayPost operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesNonWorkingDayPostRequest
 */
export interface CompaniesApiApiCompaniesNonWorkingDayPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDayPost
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDayPost
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDayPost
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDayPost
     */
    readonly companyId?: string
}

/**
 * Request parameters for apiCompaniesNonWorkingDaysGet operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesNonWorkingDaysGetRequest
 */
export interface CompaniesApiApiCompaniesNonWorkingDaysGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiApiCompaniesNonWorkingDaysGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiApiCompaniesNonWorkingDaysGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDaysGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDaysGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDaysGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesNonWorkingDaysGet
     */
    readonly companyId?: string
}

/**
 * Request parameters for apiCompaniesPost operation in CompaniesApi.
 * @export
 * @interface CompaniesApiApiCompaniesPostRequest
 */
export interface CompaniesApiApiCompaniesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly businessName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly tradeName?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly businessNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly fiscalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly contactPersonId?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly userEmail?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly companyEmail?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly createdAt?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly updatedAt?: string

    /**
     * 
     * @type {CompanyTypeEnum}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly type?: CompanyTypeEnum

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {CompanyStatus}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly status?: CompanyStatus

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly personalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly placeOfBirth?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly defaultLanguage?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly comments?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof CompaniesApiApiCompaniesPost
     */
    readonly companyFiles?: Array<File>
}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @param {CompaniesApiApiCompaniesBusinessIdSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesBusinessIdSearchGet(requestParameters: CompaniesApiApiCompaniesBusinessIdSearchGetRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesBusinessIdSearchGet(requestParameters.businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesByTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesByTypeGet(requestParameters: CompaniesApiApiCompaniesByTypeGetRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesByTypeGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.status, requestParameters.type, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesGet(requestParameters: CompaniesApiApiCompaniesGetRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.status, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdChangeStatusPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdChangeStatusPatch(requestParameters: CompaniesApiApiCompaniesIdChangeStatusPatchRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdChangeStatusPatch(requestParameters.id, requestParameters.changeCompanyStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdDelete(requestParameters: CompaniesApiApiCompaniesIdDeleteRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdGet(requestParameters: CompaniesApiApiCompaniesIdGetRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdNonWorkingDaysDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdNonWorkingDaysDelete(requestParameters: CompaniesApiApiCompaniesIdNonWorkingDaysDeleteRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdNonWorkingDaysDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdNonWorkingDaysGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdNonWorkingDaysGet(requestParameters: CompaniesApiApiCompaniesIdNonWorkingDaysGetRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdNonWorkingDaysGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdNonWorkingDaysPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdNonWorkingDaysPut(requestParameters: CompaniesApiApiCompaniesIdNonWorkingDaysPutRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdNonWorkingDaysPut(requestParameters.id, requestParameters.id2, requestParameters.startDate, requestParameters.endDate, requestParameters.description, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesIdPut(requestParameters: CompaniesApiApiCompaniesIdPutRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesIdPut(requestParameters.id, requestParameters.id2, requestParameters.businessName, requestParameters.firstName, requestParameters.lastName, requestParameters.tradeName, requestParameters.businessNumber, requestParameters.fiscalNumber, requestParameters.address, requestParameters.comments, requestParameters.phoneNumber, requestParameters.contactPersonId, requestParameters.companyEmail, requestParameters.createdAt, requestParameters.updatedAt, requestParameters.type, requestParameters.isDeleted, requestParameters.status, requestParameters.personalNumber, requestParameters.placeOfBirth, requestParameters.defaultLanguage, requestParameters.existingFileNames, requestParameters.toBeDeletedFileNames, requestParameters.companyFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesNonWorkingDayPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesNonWorkingDayPost(requestParameters: CompaniesApiApiCompaniesNonWorkingDayPostRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesNonWorkingDayPost(requestParameters.startDate, requestParameters.endDate, requestParameters.description, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesNonWorkingDaysGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesNonWorkingDaysGet(requestParameters: CompaniesApiApiCompaniesNonWorkingDaysGetRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesNonWorkingDaysGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiApiCompaniesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public apiCompaniesPost(requestParameters: CompaniesApiApiCompaniesPostRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).apiCompaniesPost(requestParameters.businessName, requestParameters.firstName, requestParameters.lastName, requestParameters.tradeName, requestParameters.businessNumber, requestParameters.fiscalNumber, requestParameters.address, requestParameters.phoneNumber, requestParameters.contactPersonId, requestParameters.userEmail, requestParameters.companyEmail, requestParameters.createdAt, requestParameters.updatedAt, requestParameters.type, requestParameters.isDeleted, requestParameters.status, requestParameters.personalNumber, requestParameters.placeOfBirth, requestParameters.defaultLanguage, requestParameters.comments, requestParameters.companyFiles, options).then((request) => request(this.axios, this.basePath));
    }
}
