import { Col, DatePicker, Form, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/modals/antd-modals";
import moment from "moment";
import { NonWorkingDaysProps } from "./companyNonWorkingDaysDataTableColumns";
import { ModalMode } from "hooks/useModalState";
import { CompaniesApi } from "api/api";
import openNotificationWithIcon from "utility/notification";

const companiesApi = new CompaniesApi();

const NonWorkingDays: React.FC<NonWorkingDaysProps> = ({
  mode = ModalMode.VIEW,
  onSave,
  isVisible = false,
  onHide,
  dateToEdit,
  companyId,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dateToEdit) {
      fetchNonWorkingDatesDetails();
    } else {
      form.resetFields();
    }
  }, [form, dateToEdit, mode]);

  const getTitle =
    mode === ModalMode.UPSERT
      ? t("non-working-days:edit.title", "Edit Date Details")
      : mode === ModalMode.ADD
      ? t("non-working-days:add.title", "Add date Details")
      : t("non-working-days:view.title", "Date Details");

  const handleOnSave = async () => {
    setIsBtnLoading(true);
    try {
      const values = await form.validateFields();
      const formattedValues = {
        ...values,
        startDate: values.startDate
          ? values.startDate.toISOString()
          : new Date().toISOString(),
        endDate: values.endDate
          ? values.endDate.toISOString()
          : new Date().toISOString(),
      };
      if (dateToEdit) {
        await companiesApi.apiCompaniesIdNonWorkingDaysPut({
          id: dateToEdit?.id as any,
          id2: dateToEdit?.id as any,
          ...formattedValues,
          companyId,
        });
        openNotificationWithIcon(
          "success",
          t(
            "non-working-daysModal:date-edit-success",
            "Date is edited successfully!"
          )
        );
      } else {
        await companiesApi.apiCompaniesNonWorkingDayPost({
          ...formattedValues,
          companyId,
        });
        openNotificationWithIcon(
          "success",
          t(
            "non-working-daysModal:date-created-success",
            "Date is created successfully!"
          )
        );
      }
      if (onHide && onSave) {
        onHide();
        onSave("" as any);
      }
    } catch (error) {
    } finally {
      setIsBtnLoading(false);
    }
  };

  const fetchNonWorkingDatesDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = (await companiesApi.apiCompaniesIdNonWorkingDaysGet({
        id: dateToEdit?.id as any,
      })) as any;
      const formattedNonWorkingDataToEdit = {
        ...dateToEdit,
        startDate: data?.startDate ? moment(data?.startDate) : null,
        endDate: data?.endDate ? moment(data?.endDate) : null,
      };

      form.setFieldsValue(formattedNonWorkingDataToEdit);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={getTitle}
      visible={isVisible}
      onOk={handleOnSave}
      onCancel={onHide}
      type="primary"
      loading={isBtnLoading}
      hideButtons={mode === ModalMode.VIEW}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <Form form={form} layout="vertical" name="dateForm">
          <Row gutter={[15, 15]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="startDate"
                label={t("non-working-days:input.start-date", "Start Date")}
                getValueProps={(i) => ({ value: moment(i) })}
                rules={[
                  {
                    required: true,
                    message: t(
                      "non-working-days.select-start-date",
                      "Please select a start date"
                    ),
                  },
                ]}
              >
                <DatePicker
                  disabled={mode === ModalMode.VIEW}
                  style={{ width: "100%", height: 50 }}
                  format="YYYY-MM-DD"
                  defaultValue={moment()}
                  placeholder={t(
                    "non-working-days:input.enter-date",
                    "Enter Date"
                  )}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="endDate"
                label={t("non-working-days:input.end-date", "End Date")}
                getValueProps={(i) => ({ value: moment(i) })}
                rules={[
                  {
                    required: true,
                    message: t(
                      "non-working-days.select-end-date",
                      "Please select a end date"
                    ),
                  },
                ]}
              >
                <DatePicker
                  disabled={mode === ModalMode.VIEW}
                  style={{ width: "100%", height: 50 }}
                  format="YYYY-MM-DD"
                  defaultValue={moment()}
                  placeholder={t(
                    "non-working-days:input.enter-date",
                    "Enter Date"
                  )}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: t(
                      "non-working-days.description",
                      "Please enter a description"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default NonWorkingDays;
