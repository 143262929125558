import { useParams } from 'react-router-dom';
import { useDecodeJWT } from "hooks/useDecodeJWT";

export const useCompanyId = () => {
  const { id: companyIdParam } = useParams();

  const token = useDecodeJWT();
  const companyIdFromToken = token?.companyId;

  const isValidCompanyId = companyIdParam && companyIdParam !== 'company';

  return isValidCompanyId ? companyIdParam : companyIdFromToken;
};
