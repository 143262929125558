import React from "react";
import Bid from "./Bid";
import BidPreviewModal from "./BidPreviewModal";
import { TFunction } from "i18next";

interface BidsModalProps {
  dealId: any;
  modalState: any;
  closeModal: () => void;
  fetchBids: () => void;

  handleModalConfirm: () => void;
  modalButtonLoading: boolean;
  previewVisible: boolean;
  selectedBid: any;
  handleModalCancel: () => void;
  t: TFunction<"translation", undefined>;
}

const BidsModal: React.FC<BidsModalProps> = ({
  dealId,
  modalState,
  closeModal,
  fetchBids,
  handleModalConfirm,
  modalButtonLoading,
  previewVisible,
  selectedBid,
  handleModalCancel,
  t,
}) => {
  return (
    <>
      <Bid
        dealId={dealId}
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        mode={modalState.mode as any}
        bidToEdit={modalState.itemForEditModal}
        onSave={fetchBids}
        t={t}
      />
      <BidPreviewModal
        visible={previewVisible}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
        bidDetails={selectedBid}
        loading={modalButtonLoading}
      />
    </>
  );
};

export default BidsModal;
