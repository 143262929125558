import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { DatePicker } from "antd";
import { ItemWraper, ButtonGroup } from "./style";
import { Button } from "../buttons/buttons";
import moment from "moment";

const CustomDateRangePicker = ({
  onApply,
  shippingStartsAt,
  shippingEndsAt,
  form,
}) => {
  const [state, setState] = useState({
    dateRangePicker: {
      selection: {
        startDate: moment(shippingStartsAt).toDate(),
        endDate: moment(shippingEndsAt).toDate(),
        key: "selection",
      },
    },
  });

  const handleRangeChange = (which) => {
    setState((prevState) => ({
      ...prevState,
      dateRangePicker: {
        ...prevState.dateRangePicker,
        ...which,
      },
    }));
  };

  useEffect(() => {
    setState({
      dateRangePicker: {
        selection: {
          startDate: moment(shippingStartsAt).toDate(),
          endDate: moment(shippingEndsAt).toDate(),
          key: "selection",
        },
      },
    });
  }, [shippingStartsAt, shippingEndsAt]);

  const handleApply = () => {
    const { dateRangePicker } = state;

    form.setFieldsValue({
      shippingStartsAt: dateRangePicker.selection.startDate,
      shippingEndsAt: dateRangePicker.selection.endDate,
    });

    console.log(dateRangePicker.selection);
    onApply(dateRangePicker.selection);
  };

  const { dateRangePicker } = state;
  const start = dateRangePicker.selection.startDate?.toString().split(" ");
  const end = dateRangePicker.selection.endDate?.toString().split(" ");

  return (
    <ItemWraper>
      <DateRangePicker
        onChange={(ranges) =>
          handleRangeChange({ selection: ranges.selection })
        }
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        className="PreviewArea"
        months={2}
        ranges={[dateRangePicker.selection]}
        direction="horizontal"
      />

      <ButtonGroup>
        {dateRangePicker.selection.startDate &&
          dateRangePicker.selection.endDate && (
            <p>{`${start[1]} ${start[2]} ${start[3]} - ${end[1]} ${end[2]} ${end[3]}`}</p>
          )}
        <Button size="small" type="primary" onClick={handleApply}>
          Apply
        </Button>
        <Button
          size="small"
          type="white"
          outlined
          onClick={() =>
            setState({
              dateRangePicker: {
                selection: {
                  startDate: null,
                  endDate: null,
                  key: "selection",
                },
              },
            })
          }
        >
          Cancel
        </Button>
      </ButtonGroup>
    </ItemWraper>
  );
};

class CustomDateRange extends React.Component {
  state = {
    startValue: null,
    endValue: null,
    endOpen: false,
  };

  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  render() {
    const { startValue, endValue, endOpen } = this.state;

    return (
      <div>
        <DatePicker
          disabledDate={this.disabledStartDate}
          showTime
          format="DD-MM-YYYY"
          value={startValue}
          placeholder="Start"
          onChange={this.onStartChange}
          onOpenChange={this.handleStartOpenChange}
          style={{ margin: "5px" }}
        />

        <DatePicker
          disabledDate={this.disabledEndDate}
          showTime
          format="DD-MM-YYYY"
          value={endValue}
          placeholder="End"
          onChange={this.onEndChange}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange}
          style={{ margin: "5px" }}
        />
      </div>
    );
  }
}

export { CustomDateRangePicker, CustomDateRange };
