import React, { useCallback, useEffect, useState } from "react";
import { Cards } from "components/cards/frame/cards-frame";
import { useTranslation } from "react-i18next";
import { useModalState } from "hooks/useModalState";
import BidsTable from "./BidsTable";
import BidsModal from "./BidsModal";
import { useBidsFetch } from "./hooks/useBidsFetch";
import { BidsApi, CodebookApi, DealsApi } from "api/api";
import { DealDto, UpdateDealCommand } from "api/models";
import { BidType } from "./bidDataTableColumns";
import openNotificationWithIcon from "utility/notification";
import { message } from "antd";

const STATUS = "STATUS";
interface BidsProps {
  dealId: any;
}

const Bids: React.FC<BidsProps> = ({ dealId }) => {
  const { t } = useTranslation();
  const [deal, setDeal] = useState<DealDto | null>(null);
  const [confirmedBidId, setConfirmedBidId] = useState<string | null>(null);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [statusSelectedId, setStatusSelectedId] = useState<any>("");
  const [pendingStatusId, setPendingStatusId] = useState<any>(null);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [selectedBid, setSelectedBid] = useState<BidType | null>(null);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [selectedBidId, setSelectedBidId] = useState<string | null>(null);
  const { data, isLoading, setQuery, fetchBids, setData } = useBidsFetch({
    dealId,
  });
  const { modalState, closeModal, handleViewClick, handleEditClick } =
    useModalState(fetchBids);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await new CodebookApi().apiCodebookByParentCodeGet({
          code: STATUS,
          pageSize: -1,
        });

        if (response.data.items) {
          setStatuses(response.data.items);
          const selectedStatus = response.data.items.find(
            (status) => status.code?.toUpperCase() === "SELECTED"
          );
          const pendingStatus = response.data.items.find(
            (status) => status.code?.toUpperCase() === "PENDING"
          );

          if (selectedStatus) {
            setStatusSelectedId(selectedStatus.id);
          }

          if (pendingStatus) {
            setPendingStatusId(pendingStatus.id);
          }
        }
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };
    fetchStatuses();
  }, []);

  const handleConfirmClick = useCallback((bid: BidType) => {
    setPreviewVisible(true);
    setSelectedBid(bid);
  }, []);

  const handleModalCancel = useCallback(() => {
    setPreviewVisible(false);
    setSelectedBid(null);
  }, []);

  useEffect(() => {
    const fetchDealById = async () => {
      if (dealId) {
        try {
          const response = await new DealsApi().apiDealsIdGet({ id: dealId });
          setDeal(response.data);
          if (response.data.selectedBidId) {
            setConfirmedBidId(response.data.selectedBidId || null);
            setSelectedBidId(response.data.selectedBidId);
          }
        } catch (error) {
          console.error("Error fetching deal by id:", error);
        }
      }
    };
    fetchDealById();
  }, [dealId]);

  const handleStatusChange = async (bidId: string, statusId: string) => {
    try {
      await new BidsApi().apiBidsIdChangeStatusPatch({
        id: bidId,
        changeBidStatusDto: { statusId },
      });

      setData((prevData: any) => ({
        ...prevData,
        items: prevData.items.map((item: any) =>
          item.id === bidId ? { ...item, statusId } : item
        ),
      }));

      if (statusId !== statusSelectedId && bidId === confirmedBidId) {
        setConfirmedBidId(null);

        const updateDealCommand: UpdateDealCommand = {
          id: dealId,
          ...deal,
          shipperId: deal?.shipper?.id,
          shipperTerminalId: deal?.shipperTerminal?.id,
          consigneeId: deal?.consignee?.id,
          consigneeTerminalId: deal?.consigneeTerminal?.id,
          selectedBidId: null,
        };

        await new DealsApi().apiDealsPut({ updateDealCommand });
      }

      if (statusId === statusSelectedId) {
        setSelectedBidId(bidId);
        openNotificationWithIcon(
          "success",
          t("bid:status-selected", "Bid status changed to Selected!")
        );
      } else {
        setSelectedBidId(null);
      }
    } catch (error) {
      console.error("Error changing status or confirming the bid:", error);
    }
  };

  const handleModalConfirm = async () => {
    setModalButtonLoading(true);

    if (selectedBid) {
      try {
        const updateDealCommand: UpdateDealCommand = {
          id: dealId,
          ...deal,
          shipperId: deal?.shipper?.id,
          shipperTerminalId: deal?.shipperTerminal?.id,
          consigneeId: deal?.consignee?.id,
          consigneeTerminalId: deal?.consigneeTerminal?.id,
          selectedBidId: selectedBid.id,
        };

        await new DealsApi().apiDealsPut({ updateDealCommand });
        // await handleStatusChange(selectedBid.id, statusSelectedId);
        setConfirmedBidId(selectedBid.id);

        if (selectedBid.id != null) {
          await new BidsApi().apiBidsConfirmPost({
            confirmBidCommand: { dealId: dealId },
          });
        }

        const otherSelectedBids = data.items.filter(
          (bid: BidType) =>
            bid.id !== selectedBid.id && bid.statusId === statusSelectedId
        );

        for (const bid of otherSelectedBids) {
          await handleStatusChange(bid.id, pendingStatusId);
        }

        setConfirmedBidId(selectedBid.id);

        openNotificationWithIcon(
          "success",
          t("bid:bid-confirmed-success", "Bid confirmed successfully!")
        );
      } catch (error) {
        console.error("Error confirming bid:", error);
      } finally {
        setModalButtonLoading(false);
      }
    }
    handleModalCancel();
  };

  return (
    <div>
      <Cards>
        <BidsTable
          data={data}
          isLoading={isLoading}
          setQuery={setQuery}
          fetchBids={fetchBids}
          statuses={statuses}
          handleStatusChange={handleStatusChange}
          handleViewClick={handleViewClick}
          handleEditClick={handleEditClick}
          handleConfirmClick={handleConfirmClick}
          confirmedBidId={confirmedBidId}
          selectedBidId={selectedBidId}
          t={t}
        />
      </Cards>
      <BidsModal
        dealId={dealId}
        modalState={modalState}
        closeModal={closeModal}
        fetchBids={fetchBids}
        handleModalConfirm={handleModalConfirm}
        modalButtonLoading={modalButtonLoading}
        previewVisible={previewVisible}
        selectedBid={selectedBid}
        handleModalCancel={handleModalCancel}
        t={t}
      />
    </div>
  );
};

export default Bids;
