import { useState } from "react";

export enum ModalMode {
  VIEW = "VIEW",
  UPSERT = "UPSERT",
  ADD = "ADD",
}

export const useModalState = (onSave: any) => {
  const [modalState, setModalState] = useState({
    addModalVisible: false,
    itemForEditModal: null,
    mode: "",
  });

  const handleViewClick = (item: any) => {
    setModalState({
      addModalVisible: true,
      mode: ModalMode.VIEW,
      itemForEditModal: item,
    });
  };

  const handleEditClick = (item: any) => {
    setModalState({
      addModalVisible: true,
      itemForEditModal: item,
      mode: ModalMode.UPSERT,
    });
  };

  const handleAddClick = () => {
    setModalState({
      addModalVisible: true,
      itemForEditModal: null,
      mode: ModalMode.ADD,
    });
  };

  const closeModal = () => {
    setModalState({
      addModalVisible: false,
      itemForEditModal: null,
      mode: "",
    });
  };

  return {
    modalState,
    handleViewClick,
    handleEditClick,
    handleAddClick,
    closeModal,
  };
};
