import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { Modal } from "components/modals/antd-modals";
import { useTranslation } from "react-i18next";
import { DestinationProps } from "components/destinations/destinationDataTableColumns";

import { DestinationsApi, CodebookApi } from "api/api";
import openNotificationWithIcon from "utility/notification";

const destinationApi = new DestinationsApi();
const codebookApi = new CodebookApi();

const { Option } = Select;

const CODE_BOOK_TYPE_COUNTRY = "CODE_BOOK_TYPE_COUNTRY";

const Destination: React.FC<DestinationProps> = ({
  onSave,
  isVisible,
  onHide,
  companyId,
}) => {
  const { t } = useTranslation();

  const [countryData, setCountryData] = useState<any>();
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const handleOnSave = async () => {
    setIsBtnLoading(true);
    try {
      const values = await form.validateFields();
      await destinationApi.apiCompaniesDestinationsPost({
        addDestinationsCommand: {
          ...values,
          companyId: companyId,
        },
      });
      openNotificationWithIcon(
        "success",
        t(
          "destinationModal:destination-created-success",
          "Destination is created successfuly!"
        )
      );
      form.resetFields();
      if (onHide && onSave) {
        onHide();
        onSave("" as any);
      }
    } catch (error) {
    } finally {
      setIsBtnLoading(false);
    }
  };

  const fetchCodebookByType = async () => {
    const response = (
      await codebookApi.apiCodebookByParentCodeGet({
        code: CODE_BOOK_TYPE_COUNTRY,
        pageSize: 1000,
      })
    ).data;
    setCountryData(response);
  };

  useEffect(() => {
    fetchCodebookByType();
  }, []);

  const handleOnCancel = () => {
    form.resetFields();
    if (onHide) {
      onHide();
    }
  };

  return (
    <>
      <Modal
        title={t("destination:add.title", "Add Destination")}
        visible={isVisible}
        onOk={handleOnSave}
        onCancel={handleOnCancel}
        type="primary"
        loading={isBtnLoading}
      >
        <Form form={form} layout="vertical" name="destinationForm">
          <Form.Item
            name="countries"
            label="Countries"
            rules={[{ required: true, message: "Please select one country!" }]}
          >
            <Select size="large" mode="multiple" allowClear>
              {countryData?.items.map((item: any) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Destination;
