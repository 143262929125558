/* tslint:disable */
/* eslint-disable */
/**
 * LogisticxApp PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StateEntityDto } from '../models';
// @ts-ignore
import { StateEntityDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { UpdateStateEntityCommand } from '../models';
/**
 * StateEntitiesApi - axios parameter creator
 * @export
 */
export const StateEntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStateEntitiesGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/state-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStateEntitiesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStateEntitiesIdGet', 'id', id)
            const localVarPath = `/api/state-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStateEntityCommand} [updateStateEntityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStateEntitiesIdPut: async (id: string, updateStateEntityCommand?: UpdateStateEntityCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStateEntitiesIdPut', 'id', id)
            const localVarPath = `/api/state-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStateEntityCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StateEntitiesApi - functional programming interface
 * @export
 */
export const StateEntitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StateEntitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStateEntitiesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateEntityDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStateEntitiesGet(pageNumber, pageSize, sortBy, sortType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStateEntitiesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateEntityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStateEntitiesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStateEntityCommand} [updateStateEntityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStateEntitiesIdPut(id: string, updateStateEntityCommand?: UpdateStateEntityCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStateEntitiesIdPut(id, updateStateEntityCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StateEntitiesApi - factory interface
 * @export
 */
export const StateEntitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StateEntitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStateEntitiesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, options?: any): AxiosPromise<StateEntityDtoOrdinalPaginatedList> {
            return localVarFp.apiStateEntitiesGet(pageNumber, pageSize, sortBy, sortType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStateEntitiesIdGet(id: string, options?: any): AxiosPromise<StateEntityDto> {
            return localVarFp.apiStateEntitiesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStateEntityCommand} [updateStateEntityCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStateEntitiesIdPut(id: string, updateStateEntityCommand?: UpdateStateEntityCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiStateEntitiesIdPut(id, updateStateEntityCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiStateEntitiesGet operation in StateEntitiesApi.
 * @export
 * @interface StateEntitiesApiApiStateEntitiesGetRequest
 */
export interface StateEntitiesApiApiStateEntitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof StateEntitiesApiApiStateEntitiesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof StateEntitiesApiApiStateEntitiesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof StateEntitiesApiApiStateEntitiesGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof StateEntitiesApiApiStateEntitiesGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof StateEntitiesApiApiStateEntitiesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiStateEntitiesIdGet operation in StateEntitiesApi.
 * @export
 * @interface StateEntitiesApiApiStateEntitiesIdGetRequest
 */
export interface StateEntitiesApiApiStateEntitiesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof StateEntitiesApiApiStateEntitiesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiStateEntitiesIdPut operation in StateEntitiesApi.
 * @export
 * @interface StateEntitiesApiApiStateEntitiesIdPutRequest
 */
export interface StateEntitiesApiApiStateEntitiesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof StateEntitiesApiApiStateEntitiesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateStateEntityCommand}
     * @memberof StateEntitiesApiApiStateEntitiesIdPut
     */
    readonly updateStateEntityCommand?: UpdateStateEntityCommand
}

/**
 * StateEntitiesApi - object-oriented interface
 * @export
 * @class StateEntitiesApi
 * @extends {BaseAPI}
 */
export class StateEntitiesApi extends BaseAPI {
    /**
     * 
     * @param {StateEntitiesApiApiStateEntitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateEntitiesApi
     */
    public apiStateEntitiesGet(requestParameters: StateEntitiesApiApiStateEntitiesGetRequest = {}, options?: AxiosRequestConfig) {
        return StateEntitiesApiFp(this.configuration).apiStateEntitiesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StateEntitiesApiApiStateEntitiesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateEntitiesApi
     */
    public apiStateEntitiesIdGet(requestParameters: StateEntitiesApiApiStateEntitiesIdGetRequest, options?: AxiosRequestConfig) {
        return StateEntitiesApiFp(this.configuration).apiStateEntitiesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StateEntitiesApiApiStateEntitiesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateEntitiesApi
     */
    public apiStateEntitiesIdPut(requestParameters: StateEntitiesApiApiStateEntitiesIdPutRequest, options?: AxiosRequestConfig) {
        return StateEntitiesApiFp(this.configuration).apiStateEntitiesIdPut(requestParameters.id, requestParameters.updateStateEntityCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
