/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId2] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsGet: async (companyId?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId2?: string, controlledTemperature?: boolean, allowsADR?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (companyId2 !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId2;
            }

            if (controlledTemperature !== undefined) {
                localVarQueryParameter['ControlledTemperature'] = controlledTemperature;
            }

            if (allowsADR !== undefined) {
                localVarQueryParameter['AllowsADR'] = allowsADR;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdDelete', 'id', id)
            const localVarPath = `/api/equipments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdGet', 'id', id)
            const localVarPath = `/api/equipments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {string} [plates] 
         * @param {number} [productionYear] 
         * @param {string} [registrationDate] 
         * @param {string} [registrationExpiration] 
         * @param {string} [chassisNumber] 
         * @param {string} [subject] 
         * @param {string} [engineNumber] 
         * @param {string} [vehicleTypeId] 
         * @param {number} [engineVolume] 
         * @param {number} [numberOfAxles] 
         * @param {string} [emissionStandard] 
         * @param {string} [color] 
         * @param {string} [manufacturer] 
         * @param {number} [dryWeight] 
         * @param {number} [enginePower] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [equipmentFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdPut: async (id: string, id2?: string, controlledTemperature?: boolean, allowsADR?: boolean, code?: string, name?: string, plates?: string, productionYear?: number, registrationDate?: string, registrationExpiration?: string, chassisNumber?: string, subject?: string, engineNumber?: string, vehicleTypeId?: string, engineVolume?: number, numberOfAxles?: number, emissionStandard?: string, color?: string, manufacturer?: string, dryWeight?: number, enginePower?: number, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, equipmentFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdPut', 'id', id)
            const localVarPath = `/api/equipments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (controlledTemperature !== undefined) { 
                localVarFormParams.append('ControlledTemperature', controlledTemperature as any);
            }
    
            if (allowsADR !== undefined) { 
                localVarFormParams.append('AllowsADR', allowsADR as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.append('Code', code as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (plates !== undefined) { 
                localVarFormParams.append('Plates', plates as any);
            }
    
            if (productionYear !== undefined) { 
                localVarFormParams.append('ProductionYear', productionYear as any);
            }
    
            if (registrationDate !== undefined) { 
                localVarFormParams.append('RegistrationDate', registrationDate as any);
            }
    
            if (registrationExpiration !== undefined) { 
                localVarFormParams.append('RegistrationExpiration', registrationExpiration as any);
            }
    
            if (chassisNumber !== undefined) { 
                localVarFormParams.append('ChassisNumber', chassisNumber as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('Subject', subject as any);
            }
    
            if (engineNumber !== undefined) { 
                localVarFormParams.append('EngineNumber', engineNumber as any);
            }
    
            if (vehicleTypeId !== undefined) { 
                localVarFormParams.append('VehicleTypeId', vehicleTypeId as any);
            }
    
            if (engineVolume !== undefined) { 
                localVarFormParams.append('EngineVolume', engineVolume as any);
            }
    
            if (numberOfAxles !== undefined) { 
                localVarFormParams.append('NumberOfAxles', numberOfAxles as any);
            }
    
            if (emissionStandard !== undefined) { 
                localVarFormParams.append('EmissionStandard', emissionStandard as any);
            }
    
            if (color !== undefined) { 
                localVarFormParams.append('Color', color as any);
            }
    
            if (manufacturer !== undefined) { 
                localVarFormParams.append('Manufacturer', manufacturer as any);
            }
    
            if (dryWeight !== undefined) { 
                localVarFormParams.append('DryWeight', dryWeight as any);
            }
    
            if (enginePower !== undefined) { 
                localVarFormParams.append('EnginePower', enginePower as any);
            }
                if (existingFileNames) {
                existingFileNames.forEach((element) => {
                    localVarFormParams.append('ExistingFileNames', element as any);
                })
            }

                if (toBeDeletedFileNames) {
                toBeDeletedFileNames.forEach((element) => {
                    localVarFormParams.append('ToBeDeletedFileNames', element as any);
                })
            }

                if (equipmentFiles) {
                equipmentFiles.forEach((element) => {
                    localVarFormParams.append('EquipmentFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {string} [plates] 
         * @param {number} [productionYear] 
         * @param {string} [registrationDate] 
         * @param {string} [registrationExpiration] 
         * @param {string} [chassisNumber] 
         * @param {string} [subject] 
         * @param {string} [engineNumber] 
         * @param {string} [vehicleTypeId] 
         * @param {number} [engineVolume] 
         * @param {number} [numberOfAxles] 
         * @param {string} [emissionStandard] 
         * @param {string} [color] 
         * @param {string} [manufacturer] 
         * @param {number} [dryWeight] 
         * @param {number} [enginePower] 
         * @param {string} [companyId] 
         * @param {Array<File>} [equipmentFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsPost: async (controlledTemperature?: boolean, allowsADR?: boolean, code?: string, name?: string, plates?: string, productionYear?: number, registrationDate?: string, registrationExpiration?: string, chassisNumber?: string, subject?: string, engineNumber?: string, vehicleTypeId?: string, engineVolume?: number, numberOfAxles?: number, emissionStandard?: string, color?: string, manufacturer?: string, dryWeight?: number, enginePower?: number, companyId?: string, equipmentFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (controlledTemperature !== undefined) { 
                localVarFormParams.append('ControlledTemperature', controlledTemperature as any);
            }
    
            if (allowsADR !== undefined) { 
                localVarFormParams.append('AllowsADR', allowsADR as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.append('Code', code as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (plates !== undefined) { 
                localVarFormParams.append('Plates', plates as any);
            }
    
            if (productionYear !== undefined) { 
                localVarFormParams.append('ProductionYear', productionYear as any);
            }
    
            if (registrationDate !== undefined) { 
                localVarFormParams.append('RegistrationDate', registrationDate as any);
            }
    
            if (registrationExpiration !== undefined) { 
                localVarFormParams.append('RegistrationExpiration', registrationExpiration as any);
            }
    
            if (chassisNumber !== undefined) { 
                localVarFormParams.append('ChassisNumber', chassisNumber as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('Subject', subject as any);
            }
    
            if (engineNumber !== undefined) { 
                localVarFormParams.append('EngineNumber', engineNumber as any);
            }
    
            if (vehicleTypeId !== undefined) { 
                localVarFormParams.append('VehicleTypeId', vehicleTypeId as any);
            }
    
            if (engineVolume !== undefined) { 
                localVarFormParams.append('EngineVolume', engineVolume as any);
            }
    
            if (numberOfAxles !== undefined) { 
                localVarFormParams.append('NumberOfAxles', numberOfAxles as any);
            }
    
            if (emissionStandard !== undefined) { 
                localVarFormParams.append('EmissionStandard', emissionStandard as any);
            }
    
            if (color !== undefined) { 
                localVarFormParams.append('Color', color as any);
            }
    
            if (manufacturer !== undefined) { 
                localVarFormParams.append('Manufacturer', manufacturer as any);
            }
    
            if (dryWeight !== undefined) { 
                localVarFormParams.append('DryWeight', dryWeight as any);
            }
    
            if (enginePower !== undefined) { 
                localVarFormParams.append('EnginePower', enginePower as any);
            }
    
            if (companyId !== undefined) { 
                localVarFormParams.append('CompanyId', companyId as any);
            }
                if (equipmentFiles) {
                equipmentFiles.forEach((element) => {
                    localVarFormParams.append('EquipmentFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId2] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsGet(companyId?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId2?: string, controlledTemperature?: boolean, allowsADR?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsGet(companyId, pageNumber, pageSize, sortBy, sortType, search, companyId2, controlledTemperature, allowsADR, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {string} [plates] 
         * @param {number} [productionYear] 
         * @param {string} [registrationDate] 
         * @param {string} [registrationExpiration] 
         * @param {string} [chassisNumber] 
         * @param {string} [subject] 
         * @param {string} [engineNumber] 
         * @param {string} [vehicleTypeId] 
         * @param {number} [engineVolume] 
         * @param {number} [numberOfAxles] 
         * @param {string} [emissionStandard] 
         * @param {string} [color] 
         * @param {string} [manufacturer] 
         * @param {number} [dryWeight] 
         * @param {number} [enginePower] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [equipmentFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdPut(id: string, id2?: string, controlledTemperature?: boolean, allowsADR?: boolean, code?: string, name?: string, plates?: string, productionYear?: number, registrationDate?: string, registrationExpiration?: string, chassisNumber?: string, subject?: string, engineNumber?: string, vehicleTypeId?: string, engineVolume?: number, numberOfAxles?: number, emissionStandard?: string, color?: string, manufacturer?: string, dryWeight?: number, enginePower?: number, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, equipmentFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdPut(id, id2, controlledTemperature, allowsADR, code, name, plates, productionYear, registrationDate, registrationExpiration, chassisNumber, subject, engineNumber, vehicleTypeId, engineVolume, numberOfAxles, emissionStandard, color, manufacturer, dryWeight, enginePower, existingFileNames, toBeDeletedFileNames, equipmentFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {string} [plates] 
         * @param {number} [productionYear] 
         * @param {string} [registrationDate] 
         * @param {string} [registrationExpiration] 
         * @param {string} [chassisNumber] 
         * @param {string} [subject] 
         * @param {string} [engineNumber] 
         * @param {string} [vehicleTypeId] 
         * @param {number} [engineVolume] 
         * @param {number} [numberOfAxles] 
         * @param {string} [emissionStandard] 
         * @param {string} [color] 
         * @param {string} [manufacturer] 
         * @param {number} [dryWeight] 
         * @param {number} [enginePower] 
         * @param {string} [companyId] 
         * @param {Array<File>} [equipmentFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsPost(controlledTemperature?: boolean, allowsADR?: boolean, code?: string, name?: string, plates?: string, productionYear?: number, registrationDate?: string, registrationExpiration?: string, chassisNumber?: string, subject?: string, engineNumber?: string, vehicleTypeId?: string, engineVolume?: number, numberOfAxles?: number, emissionStandard?: string, color?: string, manufacturer?: string, dryWeight?: number, enginePower?: number, companyId?: string, equipmentFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsPost(controlledTemperature, allowsADR, code, name, plates, productionYear, registrationDate, registrationExpiration, chassisNumber, subject, engineNumber, vehicleTypeId, engineVolume, numberOfAxles, emissionStandard, color, manufacturer, dryWeight, enginePower, companyId, equipmentFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId2] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsGet(companyId?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId2?: string, controlledTemperature?: boolean, allowsADR?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsGet(companyId, pageNumber, pageSize, sortBy, sortType, search, companyId2, controlledTemperature, allowsADR, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {string} [plates] 
         * @param {number} [productionYear] 
         * @param {string} [registrationDate] 
         * @param {string} [registrationExpiration] 
         * @param {string} [chassisNumber] 
         * @param {string} [subject] 
         * @param {string} [engineNumber] 
         * @param {string} [vehicleTypeId] 
         * @param {number} [engineVolume] 
         * @param {number} [numberOfAxles] 
         * @param {string} [emissionStandard] 
         * @param {string} [color] 
         * @param {string} [manufacturer] 
         * @param {number} [dryWeight] 
         * @param {number} [enginePower] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [equipmentFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdPut(id: string, id2?: string, controlledTemperature?: boolean, allowsADR?: boolean, code?: string, name?: string, plates?: string, productionYear?: number, registrationDate?: string, registrationExpiration?: string, chassisNumber?: string, subject?: string, engineNumber?: string, vehicleTypeId?: string, engineVolume?: number, numberOfAxles?: number, emissionStandard?: string, color?: string, manufacturer?: string, dryWeight?: number, enginePower?: number, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, equipmentFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsIdPut(id, id2, controlledTemperature, allowsADR, code, name, plates, productionYear, registrationDate, registrationExpiration, chassisNumber, subject, engineNumber, vehicleTypeId, engineVolume, numberOfAxles, emissionStandard, color, manufacturer, dryWeight, enginePower, existingFileNames, toBeDeletedFileNames, equipmentFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {string} [plates] 
         * @param {number} [productionYear] 
         * @param {string} [registrationDate] 
         * @param {string} [registrationExpiration] 
         * @param {string} [chassisNumber] 
         * @param {string} [subject] 
         * @param {string} [engineNumber] 
         * @param {string} [vehicleTypeId] 
         * @param {number} [engineVolume] 
         * @param {number} [numberOfAxles] 
         * @param {string} [emissionStandard] 
         * @param {string} [color] 
         * @param {string} [manufacturer] 
         * @param {number} [dryWeight] 
         * @param {number} [enginePower] 
         * @param {string} [companyId] 
         * @param {Array<File>} [equipmentFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsPost(controlledTemperature?: boolean, allowsADR?: boolean, code?: string, name?: string, plates?: string, productionYear?: number, registrationDate?: string, registrationExpiration?: string, chassisNumber?: string, subject?: string, engineNumber?: string, vehicleTypeId?: string, engineVolume?: number, numberOfAxles?: number, emissionStandard?: string, color?: string, manufacturer?: string, dryWeight?: number, enginePower?: number, companyId?: string, equipmentFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsPost(controlledTemperature, allowsADR, code, name, plates, productionYear, registrationDate, registrationExpiration, chassisNumber, subject, engineNumber, vehicleTypeId, engineVolume, numberOfAxles, emissionStandard, color, manufacturer, dryWeight, enginePower, companyId, equipmentFiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiEquipmentsGet operation in EquipmentApi.
 * @export
 * @interface EquipmentApiApiEquipmentsGetRequest
 */
export interface EquipmentApiApiEquipmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly companyId?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly companyId2?: string

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly controlledTemperature?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentApiApiEquipmentsGet
     */
    readonly allowsADR?: boolean
}

/**
 * Request parameters for apiEquipmentsIdDelete operation in EquipmentApi.
 * @export
 * @interface EquipmentApiApiEquipmentsIdDeleteRequest
 */
export interface EquipmentApiApiEquipmentsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiEquipmentsIdGet operation in EquipmentApi.
 * @export
 * @interface EquipmentApiApiEquipmentsIdGetRequest
 */
export interface EquipmentApiApiEquipmentsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiEquipmentsIdPut operation in EquipmentApi.
 * @export
 * @interface EquipmentApiApiEquipmentsIdPutRequest
 */
export interface EquipmentApiApiEquipmentsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly id2?: string

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly controlledTemperature?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly allowsADR?: boolean

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly plates?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly productionYear?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly registrationDate?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly registrationExpiration?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly chassisNumber?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly subject?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly engineNumber?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly vehicleTypeId?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly engineVolume?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly numberOfAxles?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly emissionStandard?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly color?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly manufacturer?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly dryWeight?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly enginePower?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly existingFileNames?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly toBeDeletedFileNames?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof EquipmentApiApiEquipmentsIdPut
     */
    readonly equipmentFiles?: Array<File>
}

/**
 * Request parameters for apiEquipmentsPost operation in EquipmentApi.
 * @export
 * @interface EquipmentApiApiEquipmentsPostRequest
 */
export interface EquipmentApiApiEquipmentsPostRequest {
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly controlledTemperature?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly allowsADR?: boolean

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly plates?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly productionYear?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly registrationDate?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly registrationExpiration?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly chassisNumber?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly subject?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly engineNumber?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly vehicleTypeId?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly engineVolume?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly numberOfAxles?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly emissionStandard?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly color?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly manufacturer?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly dryWeight?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly enginePower?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly companyId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof EquipmentApiApiEquipmentsPost
     */
    readonly equipmentFiles?: Array<File>
}

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * 
     * @param {EquipmentApiApiEquipmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiEquipmentsGet(requestParameters: EquipmentApiApiEquipmentsGetRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiEquipmentsGet(requestParameters.companyId, requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, requestParameters.companyId2, requestParameters.controlledTemperature, requestParameters.allowsADR, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentApiApiEquipmentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiEquipmentsIdDelete(requestParameters: EquipmentApiApiEquipmentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiEquipmentsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentApiApiEquipmentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiEquipmentsIdGet(requestParameters: EquipmentApiApiEquipmentsIdGetRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiEquipmentsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentApiApiEquipmentsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiEquipmentsIdPut(requestParameters: EquipmentApiApiEquipmentsIdPutRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiEquipmentsIdPut(requestParameters.id, requestParameters.id2, requestParameters.controlledTemperature, requestParameters.allowsADR, requestParameters.code, requestParameters.name, requestParameters.plates, requestParameters.productionYear, requestParameters.registrationDate, requestParameters.registrationExpiration, requestParameters.chassisNumber, requestParameters.subject, requestParameters.engineNumber, requestParameters.vehicleTypeId, requestParameters.engineVolume, requestParameters.numberOfAxles, requestParameters.emissionStandard, requestParameters.color, requestParameters.manufacturer, requestParameters.dryWeight, requestParameters.enginePower, requestParameters.existingFileNames, requestParameters.toBeDeletedFileNames, requestParameters.equipmentFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentApiApiEquipmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiEquipmentsPost(requestParameters: EquipmentApiApiEquipmentsPostRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiEquipmentsPost(requestParameters.controlledTemperature, requestParameters.allowsADR, requestParameters.code, requestParameters.name, requestParameters.plates, requestParameters.productionYear, requestParameters.registrationDate, requestParameters.registrationExpiration, requestParameters.chassisNumber, requestParameters.subject, requestParameters.engineNumber, requestParameters.vehicleTypeId, requestParameters.engineVolume, requestParameters.numberOfAxles, requestParameters.emissionStandard, requestParameters.color, requestParameters.manufacturer, requestParameters.dryWeight, requestParameters.enginePower, requestParameters.companyId, requestParameters.equipmentFiles, options).then((request) => request(this.axios, this.basePath));
    }
}
