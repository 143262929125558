import { Col, PageHeader, Row } from "antd";
import { CardToolbox, Main } from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Destinations from "components/destinations/destinations";
import Equipments from "components/equipments/equipments";
import { CompaniesApi } from "api/api";
import { useCompanyId } from "utility/useCompanyId";
import Drivers from "components/drivers/drivers";
import { hasPermission } from "utility/accessibility/hasPermission";
import CompaniesNonWorkingDays from "components/DateManager/companyNonWorkingDays";
import { useParams } from "react-router-dom";

const companiesApi = new CompaniesApi();

function isValidGuid(guid: string): boolean {
  const guidRegex =
    /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/;
  return guidRegex.test(guid);
}

export const Dashboard = () => {
  const { t } = useTranslation();
  const [userMe, setUserMe] = useState<any>(null);

  const { id } = useParams<string>();
  const companyId = useCompanyId();

  const validatedId = isValidGuid(id as string) ? id : null;

  const effectiveCompanyId = validatedId ? validatedId : companyId;

  const getCompanyData = async () => {
    const { data } = await companiesApi.apiCompaniesIdGet({
      id: effectiveCompanyId,
    });
    setUserMe(data);
  };
  useEffect(() => {
    getCompanyData();
  }, [effectiveCompanyId]);

  const components = [
    hasPermission("drivers:list") && (
      <Col xxl={12} md={12} sm={12} xs={24} key="drivers">
        <Drivers companyId={effectiveCompanyId} />
      </Col>
    ),
    hasPermission("equipments:list") && (
      <Col xxl={12} md={12} sm={12} xs={24} key="equipments">
        <Equipments companyId={effectiveCompanyId} />
      </Col>
    ),
    hasPermission("destinations:list") && (
      <Col xxl={12} md={12} sm={12} xs={24} key="destinations">
        <Destinations companyId={effectiveCompanyId} />
      </Col>
    ),
    hasPermission("companyNonWorkingDays:list") && (
      <Col xxl={12} md={12} sm={12} xs={24} key="nonWorkingDays">
        <CompaniesNonWorkingDays companyId={effectiveCompanyId} />
      </Col>
    ),
  ].filter(Boolean); // Filter out falsey values

  return (
    <div>
      <>
        <CardToolbox>
          <PageHeader
            ghost
            title={userMe?.businessName}
            subTitle={t("dashboard.title", "Dashboard")}
          />
        </CardToolbox>
        <Main>
          <Row gutter={25}>{components}</Row>
        </Main>
      </>
    </div>
  );
};
