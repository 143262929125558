import { Col, Form, Input, Row } from "antd";

import React from "react";
import { useTranslation } from "react-i18next";

function Dimensions() {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: "24px" }}>
      <Row gutter={16}>
        <Col xs={5}>
          <Form.Item
            name="length"
            label={t("initiate-quote.length", "Length")}
            rules={[
              {
                required: true,
                message: t(
                  "initiate-quote.enter-length",
                  "Please enter the length"
                ),
              },
            ]}
          >
            <Input type="number" placeholder="Length" addonAfter="cm" />
          </Form.Item>
        </Col>
        <Col xs={5}>
          <Form.Item
            name="height"
            label={t("initiate-quote.height", "Height")}
            rules={[
              {
                required: true,
                message: t(
                  "initiate-quote.enter-height",
                  "Please enter the height"
                ),
              },
            ]}
          >
            <Input type="number" placeholder="Height" addonAfter="cm" />
          </Form.Item>
        </Col>
        <Col xs={5}>
          <Form.Item
            name="width"
            label={t("initiate-quote.width", "Width")}
            rules={[
              {
                required: true,
                message: t(
                  "initiate-quote.enter-width",
                  "Please enter the width"
                ),
              },
            ]}
          >
            <Input type="number" placeholder="Width" addonAfter="cm" />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item name="volume" label="Volume">
            <Input readOnly placeholder="Volume" addonAfter="m³" />
          </Form.Item>
        </Col>
        <Col xs={5}>
          <Form.Item
            name="weight"
            label={t("initiate-quote.weight", "Weight")}
            rules={[
              {
                required: true,
                message: t(
                  "initiate-quote.enter-weight",
                  "Please enter the weight"
                ),
              },
            ]}
          >
            <Input type="number" placeholder="Weight" addonAfter="kg" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default Dimensions;
