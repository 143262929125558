import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import FeatherIcon from "feather-icons-react";
import { Button } from "components/buttons/buttons";
import { message, Popconfirm, Select } from "antd";
import { hasPermission } from "utility/accessibility/hasPermission";
import { ModalMode } from "hooks/useModalState";
import { TFunction } from "i18next";
import { getRole } from "utility/decode-jwt";
import { PredefinedRoles } from "constants/constants";

export interface BidType {
  id: string;
  transporterId: string;
  transporterName: string;
  driverId: string;
  driverName: string;
  equipmentId: string;
  equipmentName: string;
  cost: number;
  price: number;
  parkingFee: string;
  statusId: string;
  statusName: string;
}

export interface BidProps {
  t: TFunction<"translation", undefined>;
  controlledTemperature?: boolean;
  allowsADR?: boolean;
  location?: any;
  onSave?: (driver: BidType) => void;
  onHide?: () => void;
  isVisible?: boolean;
  mode?: ModalMode;
  bidToEdit?: BidType | null;
  dealId: string;
}

interface ActionHandlers {
  handleViewClick: (record: BidType) => void;
  handleEditClick: (record: BidType) => void;
  handleDeleteClick: (record: BidType) => void;
  handleConfirmClick: (record: BidType) => void;
  statuses: any[];
  handleStatusChange: (bidId: string, statusId: string) => void;
  confirmedBidId: string | null;
  selectedBidId: any;
}

export const useBidColumns = ({
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
  handleConfirmClick,
  statuses,
  handleStatusChange,
  confirmedBidId,
  selectedBidId,
}: ActionHandlers): ColumnsType<BidType> => {
  const { t } = useTranslation();

  const pendingStatusId = statuses.find(
    (status: any) => status.code?.toUpperCase() === "PENDING"
  )?.id;

  const selectedStatusId = statuses.find(
    (status: any) => status.code?.toUpperCase() === "SELECTED"
  )?.id;

  const roles = getRole();
  const isFreightForwarderRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.FREIGHT_FORWARDER)
    : roles === PredefinedRoles.FREIGHT_FORWARDER;

  const confirmBid = (record: BidType) => {
    if (record.statusId === selectedStatusId) {
      handleConfirmClick(record);
    } else {
      message.warning(
        t(
          "bids.alert.status-not-selected",
          "The status must be Selected to confirm a bid"
        )
      );
    }
  };

  return [
    {
      title: t("bids.table.transporter", "Transporter"),
      dataIndex: "transporterName",
      key: "transporterName",
      sorter: false,
    },

    {
      title: t("bids.table.driver", "Driver"),
      dataIndex: "driverName",
      key: "driverName",
      sorter: false,
    },
    {
      title: t("bids.table.equipment", "Equipment"),
      dataIndex: "equipmentName",
      key: "equipmentName",
      sorter: false,
    },
    {
      title: t("bids.table.cost", "Cost"),
      dataIndex: "cost",
      key: "cost",
      sorter: false,
      render: (cost) => `${cost} €`,
    },

    {
      title: t("bids.table.parkingFee", "Parking Fee"),
      dataIndex: "parkingFee",
      key: "parkingFee",
      sorter: false,
      render: (parkingFee) => `${parkingFee} €`,
    },
    {
      title: t("bids.table.price", "Price"),
      dataIndex: "price",
      key: "price",
      sorter: false,
      render: (price) => `${price} €`,
    },
    {
      title: t("bids.table.status", "Status"),
      dataIndex: "statusId",
      key: "statusId",
      sorter: false,
      render: (_, record) => {
        return statuses !== null && isFreightForwarderRole ? (
          <Select
            value={record.statusId || pendingStatusId}
            style={{ width: 120 }}
            onChange={(value) => {
              if (value !== selectedStatusId) {
                message.info(
                  t("bids.alert.status-changed", "Status has been changed.")
                );
              }
              handleStatusChange(record.id, value);
            }}
          >
            {statuses
              .filter((status: any) => {
                if (status.id === selectedStatusId && confirmedBidId !== null) {
                  return confirmedBidId === record.id;
                }
                return true;
              })
              .map((status: any) => (
                <Select.Option key={status.id} value={status.id}>
                  {status.name}
                </Select.Option>
              ))}
          </Select>
        ) : null;
      },
    },
    {
      title: t("bids.table.confirm", "Confirm"),
      dataIndex: "confirm",
      key: "confirm",
      sorter: false,
      render: (_, record) =>
        isFreightForwarderRole &&
        hasPermission("bids:confirm") &&
        (confirmedBidId === record.id ? (
          <span style={{ fontWeight: "bold", color: "green" }}>
            Confirmed Deal
          </span>
        ) : selectedStatusId === record.statusId ? (
          <Button
            shape="circle"
            type="light"
            onClick={() => confirmBid(record)}
            disabled={confirmedBidId !== null && confirmedBidId !== record.id}
          >
            Confirm
          </Button>
        ) : null),
    },
    {
      title: t("bids.table.action", "Action"),
      dataIndex: "action",
      key: "action",
      sorter: false,
      render: (_, record) => (
        <div className="table-actions" style={{ clear: "both" }}>
          {hasPermission("bids:list") && (
            <Button
              className="btn-icon"
              type="info"
              shape="circle"
              onClick={() => handleViewClick(record)}
            >
              <FeatherIcon icon="eye" size={25} />
            </Button>
          )}
          {isFreightForwarderRole &&
            hasPermission("bids:edit") &&
            record.id !== confirmedBidId &&
            confirmedBidId === null && (
              <Button
                className="btn-icon"
                type="info"
                shape="circle"
                onClick={() => handleEditClick(record)}
              >
                <FeatherIcon icon="edit" size={25} />
              </Button>
            )}
          {isFreightForwarderRole &&
          hasPermission("bids:delete") &&
          record.id !== confirmedBidId ? (
            <Popconfirm
              title={t(
                "bids:alert.delete-confirm",
                "This step is irreversible, are you sure you want to delete {{dynamicValue}}?",
                { dynamicValue: `${record.transporterName}` }
              )}
              onConfirm={() => handleDeleteClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="btn-icon" type="info" shape="circle">
                <FeatherIcon icon="trash" size={25} />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];
};
