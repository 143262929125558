import { useState, useEffect } from "react";
import { CompaniesApi } from "api/api";
import { useCompanyId } from "utility/useCompanyId";
import { NonWorkingDaysProps } from "../companyNonWorkingDaysDataTableColumns";

const companiesApi = new CompaniesApi();

export const useNonWorkingDaysFetch = ({companyId} : NonWorkingDaysProps) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);


  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
    companyId: companyId,
  });

  const fetchNoWorkingDays = async () => {
    setIsLoading(true);
    try {
      const response = (await companiesApi.apiCompaniesNonWorkingDaysGet(query))
        .data;
      console.log(response);
      setData(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNoWorkingDays();
  }, [query]);

  return { data, isLoading, fetchNoWorkingDays, query, setQuery };
};
