import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import FeatherIcon from "feather-icons-react";

import { Button } from "components/buttons/buttons";
import { Popconfirm } from "antd";
import { hasPermission } from "utility/accessibility/hasPermission";
import { ModalMode } from "hooks/useModalState";

export interface DriverType {
  id?: string;
  firstName?: string;
  lastName?: string;
  email: string;
}

export interface DriverProps {
  onSave?: (driver: DriverType) => void;
  onHide?: () => void;
  isVisible?: boolean;
  mode?: ModalMode;
  driverToEdit?: DriverType | null;
  companyId: string;
}

interface ActionHandlers {
  handleViewClick: (record: DriverType) => void;
  handleEditClick: (record: DriverType) => void;
  handleDeleteClick: (record: DriverType) => void;
}

export const useDriverColumns = ({
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
}: ActionHandlers): ColumnsType<DriverType> => {
  const { t } = useTranslation();

  return [
    {
      title: t("drivers.table.fullName", "Full Name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: false,
      render: (_, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: t("drivers.table.email", "Email"),
      dataIndex: "email",
      key: "email",
      sorter: false,
    },
    {
      title: t("drivers.table.action", "Action"),
      dataIndex: "action",
      key: "action",
      sorter: false,
      render: (_, record) => (
        <div className="table-actions" style={{ clear: "both" }}>
          {hasPermission("drivers:list") && (<Button
            className="btn-icon"
            type="info"
            shape="circle"
            onClick={() => handleViewClick(record)}
          >
            <FeatherIcon icon="eye" size={25} />
          </Button>
          )}
          {hasPermission("drivers:edit") && (
            <Button
              className="btn-icon"
              type="info"
              shape="circle"
              onClick={() => handleEditClick(record)}
            >
              <FeatherIcon icon="edit" size={25} />
            </Button>
          )}
          {hasPermission("drivers:delete") ? (
            <Popconfirm
              title={t(
                "drivers:alert.delete-confirm",
                "This step is irreversible, are you sure you want to delete {{dynamicValue}}?",
                { dynamicValue: `${record.firstName} ${record.lastName}` }
              )}
              onConfirm={() => handleDeleteClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="btn-icon" type="info" shape="circle">
                <FeatherIcon icon="trash" size={25} />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];
};
