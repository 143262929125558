import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";

import { ProfileAuthorBox } from "./style";
import { Cards } from "components/cards/frame/cards-frame";
import styled from "styled-components";

const ProfileLink = styled(NavLink)`
  color: ${(props) => (props.active ? "#5F63F2" : "default")} !important;
  font-weight: ${(props) => (props.active ? "600" : "400")} !important;
`;

export const BoxTypes = {
  profile: "profile",
  setPassword: "setPassword",
  company: "company"
};
function AuthorBox({ onClick, type, labels }) {
  return (
    <ProfileAuthorBox>
      <Cards headless>
        <nav className="settings-menmulist">
          <ul>
            {labels.map(label => (
              <li>
                <ProfileLink
                  active={type === label.label}
                  onClick={() => onClick(label.label)}
                >
                  <FeatherIcon icon={label.icon} size={14} />
                  {label.title}
                </ProfileLink>
              </li>

            ))}

          </ul>
        </nav>
      </Cards>
    </ProfileAuthorBox>
  );
}

AuthorBox.propTypes = {};

export default AuthorBox;
