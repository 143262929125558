import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { ColumnsType } from "antd/lib/table";
import styled from "styled-components";
import { Button } from "components/buttons/buttons";
import { hasPermission } from "utility/accessibility/hasPermission";
import { DealDto, DealStatusEnum } from "api/models";
import { PredefinedRoles } from "constants/constants";
import { getRole } from "utility/decode-jwt";
import { Tooltip } from "antd";

const ConfirmedBadge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 3px 6px;
  border-radius: 10px;
  background: #c0dd77;
  color: #7aa809;
`;

interface ActionHandlers {
  handleViewClick: any;
  handlePlaceBidClick: any;
  handleEditClick: any;
  filterType: number; // Add filterType to the interface
}

export const useWorkbookColumns = ({
  handleViewClick,
  handlePlaceBidClick,
  handleEditClick,
  filterType,
}: ActionHandlers): ColumnsType<DealDto> => {
  const { t } = useTranslation();
  const roles = getRole();

  const isFreightForwarderRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.FREIGHT_FORWARDER)
    : roles === PredefinedRoles.FREIGHT_FORWARDER;

  const columns: ColumnsType<DealDto> = [
    {
      title: t("company:input.commodity", "Commodity"),
      dataIndex: "commodity",
      key: "commodity",
    },
    {
      title: t("company:input.pickLocation", "Pick-up Location"),
      dataIndex: "pickLocation",
      key: "pickLocation",
    },
    {
      title: t("company:input.dropLocation", "Drop-off Location"),
      dataIndex: "dropLocation",
      key: "dropLocation",
    },
    {
      title: t("company:input.typeOfTransport", "Type of Transport"),
      dataIndex: "dedicatedTruck",
      key: "typeOfTransport",
      render: (dedicatedTruck) =>
        dedicatedTruck ? "Dedicated Truck" : "Regular Transport",
    },
    {
      title: t("company:input.consignee", "Consignee"),
      dataIndex: ["consignee", "businessName"],
      key: "consignee",
    },
    {
      title: t("company:input.shipper", "Shipper"),
      dataIndex: ["shipper", "businessName"],
      key: "shipper",
    },
    // Status column will be conditionally added here
    {
      title: t("company:table.action", "Action"),
      key: "action",
      render: (_, record) => (
        <div className="table-actions" style={{ clear: "both" }}>
          {hasPermission("deals:list") &&
            (isFreightForwarderRole &&
            record.status !== DealStatusEnum.NUMBER_2 ? (
              <Tooltip title={t("company:tooltip.placeBid", "Place Bid")}>
                <Button
                  className="btn-icon"
                  type="info"
                  key="add-codebook"
                  onClick={() => handlePlaceBidClick(record.id!)}
                >
                  <FeatherIcon icon="plus-square" size={25} />
                </Button>
              </Tooltip>
            ) : null)}
          {hasPermission("deals:list") && (
            <Tooltip title={t("company:tooltip.viewDetails", "View Details")}>
              <Button
                className="btn-icon"
                type="info"
                shape="circle"
                key="view-details"
                onClick={() => handleViewClick(record.id)}
              >
                <FeatherIcon icon="eye" size={25} />
              </Button>
            </Tooltip>
          )}
          {hasPermission("deals:list") &&
            record.canEditDeal &&
            record.bids?.length === 0 && (
              <Tooltip title={t("company:tooltip.editDeal", "Edit Deal")}>
                <Button
                  className="btn-icon"
                  type="info"
                  shape="circle"
                  key="edit-deal"
                  onClick={() => handleEditClick(record.id)}
                >
                  <FeatherIcon icon="edit" size={25} />
                </Button>
              </Tooltip>
            )}
        </div>
      ),
    },
  ];

  // Conditionally add the Status column if filterType is "All"
  if (filterType === DealStatusEnum.NUMBER_3) {
    columns.splice(6, 0, {
      title: t("company:input.status", "Status"),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div style={{ display: "flex" }}>
          {status === DealStatusEnum.NUMBER_2 ? (
            <ConfirmedBadge>✓ CONFIRMED</ConfirmedBadge>
          ) : (
            <p>QUOTING</p>
          )}
        </div>
      ),
    });
  }

  return columns;
};
