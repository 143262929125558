import ForgotPassword from "pages/authentication/ForgotPassword";
import ResetPasswordPage from "pages/authentication/ResetPasswordPage";
import SetNewPasswordPage from "pages/authentication/SetNewPasswordPage";
import SignIn from "pages/authentication/SignIn";
import NotFound from "pages/authorization/not-found/not-found";
import UnAuthorized from "pages/authorization/unAuthorized/unAuthorized";
import { CodebookCommonPage } from "pages/codebooks/CodebookCommonPage/CodebookCommonPage";
import EmailPage from "pages/localization/email/EmailPage";
import SystemLanguages from "pages/localization/system-languages/SystemLanguages";
import Translations from "pages/localization/translations/Translations";
import LogsPage from "pages/logs-audit/LogsPage";
import Settings from "pages/settings/Settings";
import UserGroupPage from "pages/user-groups/UserGroupPage";
import CreateUserPage from "pages/users/CreateUserPage";
import UpdateUserPage from "pages/users/UpdateUserPage";
import UsersPage from "pages/users/UsersPage";
import { RouteProps } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
import ComingSoon from "pages/coming-soon/ComingSoon";
import { CodebookTypePage } from "pages/codebooks/CodebookTypePage/CodebookTypePage";
import { Dashboard } from "pages/dashboard/Dashboard";
import CustomOffices from "../pages/custom-office/CustomOffice";
import CreateCompanyPage from "../pages/company/CreateCompanyPage";
import { CompanyTypeEnum } from "../api";
import CompanyPage from "../pages/company/CompanyPage";
import InitiateDealsPage from "pages/deals/InitiateDealsPage";
import DealsPage from "pages/deals/DealsPage";
import DealsList from "components/deals/DealsList";
import ViewDetails from "pages/deals/ViewDetails";
import WorkbookPage from "pages/deals/WorkbookPage";

export type CustomRouteProps = {
  hasAccess: () => boolean;
} & RouteProps;

export const MainRoutes: CustomRouteProps[] = [
  {
    index: true,
    path: "/",
    element: <ComingSoon />,
    hasAccess: () => true,
  },
  {
    index: false,
    path: "/languages",
    element: <SystemLanguages />,
    hasAccess: () => hasPermission("languages:list"),
  },
  {
    index: true,
    path: "/users/:id/edit",
    element: <UpdateUserPage />,
    hasAccess: () => hasPermission("users:add"),
  },
  {
    index: false,
    path: "/users/create",
    element: <CreateUserPage />,
    hasAccess: () => hasPermission("users:add"),
  },
  {
    index: false,
    path: "/users",
    element: <UsersPage />,
    hasAccess: () => hasPermission("users:list"),
  },
  {
    index: false,
    path: "/user-groups",
    element: <UserGroupPage />,
    hasAccess: () => hasPermission("user-groups:list"),
  },
  {
    index: false,
    path: "/logs",
    element: <LogsPage />,
    hasAccess: () => hasPermission("logs:list"),
  },
  {
    index: false,
    path: "/unauthorized",
    element: <UnAuthorized />,
    hasAccess: () => true,
  },
  {
    index: false,
    path: "/profile",
    element: <Settings />,
    hasAccess: () => true,
  },
  {
    index: false,
    path: "/dashboard/:id",
    element: <Dashboard />,
    hasAccess: () => true,
  },
  {
    path: "/translations",
    element: <Translations />,
    hasAccess: () => hasPermission("translations:list"),
  },
  {
    path: "/custom-offices",
    element: <CustomOffices />,
    hasAccess: () => hasPermission("custom-offices:list"),
  },

  {
    index: false,
    path: "/quotes",
    element: <DealsPage />,
    hasAccess: () => hasPermission("deals:list"),
  },
  {
    index: false,
    path: "/workbook",
    element: <WorkbookPage />,
    hasAccess: () => hasPermission("deals:list"),
  },
  {
    index: false,
    path: "/deal/initiate",
    element: <InitiateDealsPage />,
    hasAccess: () => hasPermission("deals:add"),
  },
  {
    index: true,
    path: "/deal/:id/edit",
    element: <InitiateDealsPage />,
    hasAccess: () => hasPermission("deals:edit"),
  },
  {
    index: true,
    path: "/deal/:id",
    element: <ViewDetails />,
    hasAccess: () => hasPermission("deals:list"),
  },
  {
    path: "/codebook-types",
    element: <CodebookTypePage />,
    hasAccess: () => true || hasPermission("codebook-types:list"),
  },
  //here should be dynamic codebooks types route like type-of-vehicles
  {
    path: "/codebook-types/:id",
    element: <CodebookCommonPage />,
    hasAccess: () => true,
  },
  {
    path: "/email",
    element: <EmailPage />,
    hasAccess: () => hasPermission("email-options:add"),
  },
  {
    path: "/add-transporter",
    element: <CreateCompanyPage type={CompanyTypeEnum.NUMBER_0} />,
    hasAccess: () => hasPermission("transporters:add"),
  },
  {
    path: "/transporters",
    element: <CompanyPage type={CompanyTypeEnum.NUMBER_0} />,
    hasAccess: () => hasPermission("transporters:list"),
  },
  {
    path: "/clients",
    element: <CompanyPage type={CompanyTypeEnum.NUMBER_1} />,
    hasAccess: () => hasPermission("clients:list"),
  },
  {
    path: "/add-client",
    element: <CreateCompanyPage type={CompanyTypeEnum.NUMBER_1} />,
    hasAccess: () => hasPermission("clients:add"),
  },
  {
    path: "/freight-forwarders",
    element: <CompanyPage type={CompanyTypeEnum.NUMBER_2} />,
    hasAccess: () => hasPermission("freight-forwarders:list"),
  },
  {
    path: "/add-freight-forwarder",
    element: <CreateCompanyPage type={CompanyTypeEnum.NUMBER_2} />,
    hasAccess: () => hasPermission("freight-forwarders:add"),
  },
  {
    index: false,
    path: "*",
    element: <NotFound />,
    hasAccess: () => true,
  },
];

export const AuthRoutes: RouteProps[] = [
  {
    index: false,
    path: "/login",
    element: <SignIn />,
  },
  {
    index: false,
    path: "/set-new-password",
    element: <SetNewPasswordPage />,
  },
  {
    index: false,
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    index: false,
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
];
