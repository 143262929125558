import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Col, Row, Collapse, Checkbox, Spin, Form, Input, Select } from "antd";
import { UserCard } from "../style";
import Heading from "../../components/heading/heading";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";
import ReactSelect from "react-select/creatable";
import openNotificationWithIcon from "../../utility/notification";
import { useAuthorization } from "../../hooks/useAuthorization";
import { AuthApi, UserGroupsApi } from "../../api";
import { useTranslation } from "react-i18next";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import { BasicFormWrapper } from "container/styled";
import { PredefinedRoles } from "constants/constants";
import loggedAsSysAdmin from "utility/loggedAsSysAdmin";
const authApi = new AuthApi();

const { Panel } = Collapse;
const { Option } = Select;
const userGroupsApi = new UserGroupsApi();

const UserGroupCard = ({ userGroup, allUsers, onClickForDelete, onUpdate }) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const [form] = Form.useForm();
  const [userGroupDetails, setUserGroupDetails] = useState(userGroup);
  const [showModal, setShowModal] = useState(false);
  const [userGroupAlreadyExists, setUserGroupAlreadyExists] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [modulesWithFunctionalities, setModulesWithFunctionalities] = useState(
    []
  );
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [loadingUserGroupDetails, setLoadingUserGroupsDetails] =
    useState(false);
  const [showFunctionalities, setShowFunctionalities] = useState(false);
  const [checkedFunctionalitiesCount, setCheckedFunctionalitiesCount] =
    useState(0);

  useEffect(() => {
    setUserGroupDetails(userGroup);
  }, [userGroup]);

  const getUserGroupDetails = async () => {
    try {
      setLoadingUserGroupsDetails(true);
      const {
        data: { users, moduleFunctionalities, accessLevel, accessLevelDesc },
      } = await userGroupsApi.userGroupsNameGet({
        name: userGroupDetails?.name,
      });
      setSelectedUsers(
        users.map((item) => ({
          value: item.id,
          label: `${item.fullName}`,
        }))
      );
      setModulesWithFunctionalities(moduleFunctionalities);

      const checkedCount = moduleFunctionalities
        .flatMap((module) => module.functionalities)
        .reduce(
          (count, functionality) => count + (functionality.checked ? 1 : 0),
          0
        );
      setCheckedFunctionalitiesCount(checkedCount);

      form.setFieldsValue({ name: userGroupDetails?.name, accessLevel });
    } catch (error) {
    } finally {
      setLoadingUserGroupsDetails(false);
    }
  };

  const handleEditUserGroup = () => {
    getUserGroupDetails();
    setShowModal(true);
  };

  const nameValue = useDebouncedValue({
    delay: 300,
    value: Form.useWatch("name", form),
  });

  useEffect(() => {
    const validateUser = async () => {
      try {
        const name = form.getFieldValue("name")?.trim();
        if (!name || name === userGroupDetails.name) {
          return;
        }

        const response = await userGroupsApi.userGroupsAvailableNameGet({
          name,
        });

        if (!response.data) {
          setUserGroupAlreadyExists(true);
          form.setFields([
            {
              name: "name",
              errors: [
                t(
                  "user-groups:validations.user-group-already-exists",
                  "User group already exists!"
                ),
              ],
            },
          ]);
          return;
        }

        form.setFields([
          {
            name: "name",
            errors: [],
          },
        ]);
        setUserGroupAlreadyExists(false);
      } catch (err) {}
    };

    validateUser();
  }, [nameValue]);

  const customPanelStyle = {
    background: "#F8F9FB",
    borderRadius: 3,
    marginBottom: 20,
    border: 0,
    overflow: "hidden",
  };

  const submitEdit = async () => {
    if (userGroupAlreadyExists) {
      return;
    }
    let permissions = [];

    modulesWithFunctionalities.forEach((module) => {
      module.functionalities.forEach((fun) => {
        fun.checked && permissions.push(fun);
      });
    });

    try {
      setIsEditLoading(true);
      const payload = {
        name: userGroupDetails.name,
        newName: form.getFieldValue("name"),
        accessLevel: form.getFieldValue("accessLevel"),
        permissions,
        users: selectedUsers.map((x) => ({ id: x.value })),
      };
      const { data } = await userGroupsApi.userGroupsPut({
        editUserGroupCommand: payload,
      });
      setUserGroupDetails(data);
      onUpdate({ ...data });
      setShowModal(false);
      openNotificationWithIcon(
        "success",
        t("user-groups:edited.successfully", "User group edited successfully!")
      );

      if (
        userGroupDetails.name === PredefinedRoles.SUPER_ADMINISTRATOR &&
        loggedAsSysAdmin()
      ) {
        let user = JSON.parse(localStorage.getItem("user"));

        if (!user) return;

        let { accessToken } = user;
        const { data } = await authApi.apiAuthExchangeTokenPost({
          exchangeTokenRequest: { accessToken },
        });
        localStorage.setItem("user", JSON.stringify(data));
        window.location.reload();
      }
    } catch (err) {
    } finally {
      setIsEditLoading(false);
    }
  };

  const onFunctionalityStateChange = (e, moduleId, name) => {
    e.stopPropagation();
    setModulesWithFunctionalities((m) =>
      m.map((x) =>
        x.id === moduleId
          ? {
              ...x,
              functionalities: x.functionalities.map((functionality) =>
                functionality.name === name
                  ? { ...functionality, checked: e.target.checked }
                  : functionality
              ),
              checked:
                x.functionalities
                  .filter((y) => y.name !== name)
                  .every((f) => f.checked) && e.target.checked,
            }
          : x
      )
    );
  };

  const onModuleStateChanged = (e, id) => {
    e.stopPropagation();
    setModulesWithFunctionalities((modules) =>
      modules.map((module) =>
        module.id === id
          ? {
              ...module,
              functionalities: module.functionalities.map((f) => ({
                ...f,
                checked: !module?.checked,
              })),
              checked: !module?.checked,
            }
          : module
      )
    );
  };

  return (
    <UserCard>
      <div className="card user-card" style={{ marginBottom: 20 }}>
        <Cards headless>
          <figure>
            {/* <img src={require(`../../../${img}`)} alt="" /> */}
          </figure>
          <figcaption>
            <div className="card__content">
              <Heading className="card__name" as="h3">
                <Link to="#">{userGroupDetails?.name}</Link>
              </Heading>
            </div>

            <div className="card__actions" style={{ margin: "50px 0" }}>
              {hasPermission("user-groups:edit") && (
                <Button
                  size="small"
                  type="primary"
                  onClick={handleEditUserGroup}
                >
                  <FeatherIcon icon="edit" size={14} />
                  {userGroupDetails.isEditable
                    ? t("user-groups:edit", "Edit")
                    : t("user-groups:view", "View")}
                </Button>
              )}
              {hasPermission("user-groups:deactivate") &&
                userGroupDetails.isEditable && (
                  <Button
                    onClick={onClickForDelete}
                    style={{ marginLeft: 20 }}
                    size="small"
                    outlined
                    type="danger"
                  >
                    <FeatherIcon icon="trash" size={14} />
                    {t("user-groups:delete", "Delete")}
                  </Button>
                )}
            </div>
            <div className="card__info">
              <Row gutter={15}>
                <Col xs={12}>
                  <div className="info-single">
                    <Heading className="info-single__title" as="h2">
                      {userGroupDetails?.totalMembers}
                    </Heading>
                    <p>{t("user-groups:members", "Members")}</p>
                  </div>
                </Col>
                {/* <Col xs={8}>
                  <div className="info-single">
                    <Heading className="info-single__title" as="h2">
                      {userGroupDetails?.totalActiveMembers}
                    </Heading>
                    <p>{t("global:active", "Active")}</p>
                  </div>
                </Col> */}
                <Col xs={12}>
                  <div className="info-single">
                    <Heading className="info-single__title" as="h2">
                      {userGroupDetails?.totalPermissions}
                    </Heading>
                    <p>{t("user-groups:permission", "Permissions")}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </figcaption>
        </Cards>
      </div>

      <Modal
        type="primary"
        title={userGroupDetails?.name}
        visible={showModal}
        footer={
          <div>
            <Button size="small" type="primary" onClick={submitEdit}>
              {t("global:save", "Save")}
            </Button>
          </div>
        }
        onCancel={() => setShowModal(!showModal)}
      >
        {isEditLoading || loadingUserGroupDetails ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        ) : (
          <>
            <BasicFormWrapper>
              <Form
                id="addUserGroup"
                form={form}
                name="Functionalities"
                onFinish={() => {}}
              >
                <Form.Item
                  label={t("global:name", "Name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "user-groups:validations.write-group-name",
                        "Please write Name!"
                      ),
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Form>
            </BasicFormWrapper>
            <p style={{ margin: "1rem 0 0 0" }}>
              {t("user-groups:assigned-users", "Assigned users")}:{" "}
            </p>
            <ReactSelect
              isMulti
              value={selectedUsers}
              onChange={setSelectedUsers}
              name="selectedStreets"
              options={allUsers}
              disabled={userGroupAlreadyExists}
              className="basic-multi-select"
            />
            <div style={{ marginTop: 20 }}>
              <p
                style={{
                  cursor: "pointer",
                  marginBottom: 0,
                  fontSize: 16,
                  textDecorationLine: "none",
                }}
                onClick={() => setShowFunctionalities((p) => !p)}
              >
                {showFunctionalities
                  ? t("global:collapse", "Collapse")
                  : t("global:expand", "Expand")}{" "}
                {checkedFunctionalitiesCount}{" "}
                {t("global:functionalities", "Functionalities")}
                <FeatherIcon
                  icon={`chevron-${showFunctionalities ? "up" : "down"}`}
                  size={16}
                />
              </p>
              {showFunctionalities && !userGroupAlreadyExists && (
                <div>
                  {modulesWithFunctionalities
                    .filter((module) => module.functionalities.length > 0)
                    .map((module) => (
                      <Collapse bordered={false}>
                        <Panel
                          header={
                            <Checkbox
                              className={`bookItem__checkbox `}
                              checked={module.checked}
                              onChange={(value) =>
                                onModuleStateChanged(value, module.id)
                              }
                            >
                              <strong>{module.name}</strong>
                            </Checkbox>
                          }
                          key="1"
                          style={customPanelStyle}
                          forceRender
                        >
                          {module.functionalities.map((x) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 10,
                              }}
                            >
                              <Checkbox
                                className={`bookItem__checkbox `}
                                checked={x.checked}
                                onChange={(value) =>
                                  onFunctionalityStateChange(
                                    value,
                                    module.id,
                                    x.name
                                  )
                                }
                              >
                                <strong>{x.description}</strong>
                              </Checkbox>
                            </div>
                          ))}
                        </Panel>
                      </Collapse>
                    ))}
                </div>
              )}
            </div>
          </>
        )}
      </Modal>
    </UserCard>
  );
};

// UserGroupCard.propTypes = {
//   user: PropTypes.object,
// };

export default UserGroupCard;
